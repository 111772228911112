import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import Pbf from "pbf";
import geobuf from "geobuf";
import GeoJSON from "ol/format/GeoJSON";

import { Style } from "ol/style";
import { Fill, Stroke } from "ol/style";
import { getUid } from "ol/util";
export default class Layer extends VectorLayer {
    constructor() {
        super({
            source: new VectorSource(),
            style: function(feature) {
                let highlightStyle = new Style({
                    stroke: new Stroke({
                        color: "#f00",
                        width: 1
                    }),
                    fill: new Fill({
                        color: "rgba(255,0,0,0.1)"
                    })
                });
                return highlightStyle;
            }
        });

        this._originalFlatCoordinates = null;
    }

    setMap(map) {
        this._map = map;
    }

    transform(vector) {
        const source = this.getSource();
        const features = source.getFeatures();
        let original = {};
        if (this._originalFlatCoordinates === null) {
            
            for (let feature of features) {
                const featureKey = getUid(feature);
                const geometry = feature.getGeometry(); 
                original[featureKey] = geometry.flatCoordinates;
            }
        }

        for (let feature of features) {
            const featureKey = getUid(feature);
            const geometry = feature.getGeometry(); 
            original[featureKey] = geometry.flatCoordinates;
        }
    }

    loadMap(name) {
        loadMap(name).then(features => {
            const source = this.getSource();
            source.clear();
            source.addFeatures(features);
            // console.log(`loaded ${features.length} '${segment}' features`);
            this._map.getView().fit(source.getExtent(), {
                size: this._map.getSize()
            });
        });
    }
}

const geojsonFormat = new GeoJSON();

async function loadMap(mapName) {
    const resp = await fetch(`/api/calibrator/map/${mapName}`);
    const geojson = geobuf.decode(new Pbf(await resp.arrayBuffer()));

    if (!geojson || !geojson.features) {
        console.error("加载无效的GeoJSON");
        return [];
    }

    return geojsonFormat.readFeatures(geojson);
}

// transform: [a, b, c, d, dx, dy]
// function affineTransform2D(geometry, transform) {
//     if (!geometry.flatCoordinates) {
//         console.warn("no flatCoordinates: ", geometry);
//         return;
//     }

//     const { flatCoordinates, stride } = geometry;

//     let i = 0;
//     for (let j = 0; j < flatCoordinates.length; j += stride) {
//         const x = flatCoordinates[j];
//         const y = flatCoordinates[j + 1];
//         flatCoordinates[i++] =
//             transform[0] * x + transform[2] * y + transform[4];
//         flatCoordinates[i++] =
//             transform[1] * x + transform[3] * y + transform[5];
//     }

//     geometry.changed();
// }
