// callback(feature, layer)
export function handleMapClick(map, callback) {
    const clickHandler = (evt) => {
        const pixel = map.getEventPixel(evt);
        map.forEachFeatureAtPixel(pixel, (feature, layer) => {
            return callback(feature, layer);
        });
    };

    const viewport = map.getViewport();
    viewport.addEventListener("click", clickHandler);
    return () => {
        viewport.removeEventListener("click", clickHandler);
    };
}
