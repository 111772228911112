// import TileGrid from "ol/tilegrid/TileGrid";

import MVT from "ol/format/MVT";
// import Feature from "ol/Feature";
import { VectorTile as VectorTileSource } from "ol/source";
import { VectorTile as VectorTileLayer } from "ol/layer";
import { Style } from "ol/style";
// import { Icon } from "ol/style";
import { Fill, Stroke } from "ol/style";

import { getProjection } from "../projection";

const TILE_EXTENT = [39000000, 3950000, 39800000, 4750000];
const projection = getProjection("EPSG:4527");

projection.setExtent(TILE_EXTENT);

export default class FirmOutlineTileLayer extends VectorTileLayer {
    constructor() {
        super({
            source: new VectorTileSource({
                format: new MVT(),
                projection: projection,
                maxZoom: 18,
                url: "/api/map/mvt/{z}_{x}_{y}_firm.pbf",
                tileLoadFunction: tileLoadFunction,
            }),
            style: (feature) => {
                // console.log("hover: ", feature.get("hover"), feature);
                if (feature.properties_.hover) {
                    return hoverFirmFeatureStyle;
                }

                return firmFeatureStyle;
            },
        });

        this._hoverFeature = null;
    }

    setMap(map) {
        this._map = map;
    }

    handleMouseMoveEvent(event, callback) {
        if (!callback) return;

        const pixel = this._map.getPixelFromCoordinate(event.coordinate);

        let feature, layer;
        this._map.forEachFeatureAtPixel(pixel, (_feature, _layer) => {
            if (_layer === this) {
                feature = _feature;
                layer = _layer;
                return true;
            }
        });

        if (this._hoverFeature) {
            if (!feature || layer !== this) {
                this._hoverExit(callback);
                return;
            }

            if (feature !== this._hoverFeature) {
                this._hoverExit(callback);
                this._hoverEnter(feature, callback);
            }
        } else {
            if (!feature || layer !== this || feature === this._hoverFeature)
                return;

            this._hoverEnter(feature, callback);
        }
    }

    _hoverEnter(feature, callback) {
        const firms = feature.get("firms");

        let labelPoint = feature.get("label_point");
        if (labelPoint) {
            labelPoint = this._map.getPixelFromCoordinate(labelPoint);
        }

        const estateSn = feature.get("estate_sn");

        this._hoverFeature = feature;

        console.log();
        feature.properties_.hover = true;
        this.changed();
        if (callback) {
            callback({ pixel: labelPoint, estateSn, firms, hover: true });
        }
    }

    _hoverExit(callback) {
        if (!this._hoverFeature) return;

        this._hoverFeature.properties_.hover = false;
        this.changed();

        this._hoverFeature = null;

        if (callback) {
            callback({ hover: false });
        }
    }
}

const firmFeatureStyle = new Style({
    stroke: new Stroke({
        color: "hsla(300, 62%, 47%, 1)",
        width: 1,
    }),
    fill: new Fill({
        color: "rgba(0, 0, 0, 0.1)",
    }),
});

const hoverFirmFeatureStyle = new Style({
    stroke: new Stroke({
        color: "hsla(300, 62%, 67%, 1)",
        width: 2,
    }),
    fill: new Fill({
        color: "rgba(0, 0, 0, 0.1)",
    }),
});

function tileLoadFunction(tile, url) {
    tile.setLoader(function (extent, resolution, projection) {
        fetch(url).then(function (response) {
            response.arrayBuffer().then(function (data) {
                const format = tile.getFormat();
                const features = format.readFeatures(data, {
                    extent: extent,
                    featureProjection: projection,
                });

                for (let feature of features) {
                    let value;

                    value = feature.properties_.firms;
                    if (typeof value === "string") {
                        feature.properties_.firms = JSON.parse(value);
                    }

                    value = feature.properties_.label_point;
                    if (typeof value === "string") {
                        value = JSON.parse(value);
                        feature.properties_.label_point = value;
                    }
                }

                tile.setFeatures(features);
            });
        });
    });
}
