import React, { useState, useImperativeHandle } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { deleteResource } from "../../util/json";
import DialogContent from "@material-ui/core/DialogContent";
// import clsx from "clsx";

import AsyncButton from "../../util/panel/AsyncButton";

export default React.forwardRef((props, ref) => {
    const [item, setItem] = useState(null);

    useImperativeHandle(
        ref,
        () => ({
            removeFirm: ({ firmSn }, onDone) => {
                setItem({
                    firmSn,
                    onDone,
                });
            },
        }),
        []
    );

    const handleCommit = async (e) => {
        const { firmSn, message } = item;
        await deleteResource(`/api/firm/${firmSn}/profile`, {
            firm_sn: firmSn,
            rev_notes: message,
        });

        setTimeout(()=> {
            setItem(null);
            if (item.onDone) {
                item.onDone({ firmSn, rev_notes: message });
            }            
        }, 10);

        



        // setTimeout(() => {
        //     setItem(null);
        // }, 50);
    };

    const handleClose = () => {
        setItem(null);
    };

    if (item === null) {
        return null;
    }

    return (
        <Dialog
            open={item !== null}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <StyledDiv>
                <p className="content">删除（归档）企业登记</p>
                <TextField
                    autoFocus
                    margin="dense"
                    id="rev_notes"
                    label="变更备注"
                    fullWidth
                    onChange={(e) =>
                        setItem({ ...item, message: e.target.value })
                    }
                />
            </StyledDiv>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    取消
                </Button>
                <AsyncButton onAction={handleCommit} color="primary">
                    确定
                </AsyncButton>
            </DialogActions>
        </Dialog>
    );
});

const StyledDiv = styled(DialogContent)`
    padding: 20px 40px;
    min-width: 30em;

    .content {
    }
`;
