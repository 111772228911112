import { useReducer } from "react";

const actions = {
    OPEN_MENU: (state, action) => {
        const { open } = action;

        if (state.open === open) return state;

        return {
            ...state,
            open: open,
        };
    },
    LOCK_FIRM: (state, action) => {
        return {
            ...state,
            feature: {
                ...state.feature,
                lockedFirmSn: action.firmSn,
            },
        };
    },
    UNLOCK_FIRM: (state, action) => {
        return {
            ...state,
            feature: {
                ...state.feature,
                lockedFirmSn: null,
            },
        };
    },
    CENTER_ESTATE: (state, action) => {
        if (state.feature) {
            const feature = state.feature;

            let centered;
            if (feature.centered === undefined || feature.centered === null) {
                centered = 0;
            } else {
                centered = feature.centered;
            }

            return {
                ...state,
                feature: {
                    ...feature,
                    centered: centered + 1,
                },
            };
        }
    },
    TOUCH_FEATURE: (state, action) => {
        return {
            ...state,
            feature: {
                ...state.feature,
                forceUpdate: state.feature.forceUpdate + 1,
            },
        };
    },
    SHOW_ESTATE: (state, action) => {
        if (!action.estateSn) {
            return {
                ...state,
                estate: null,
            };
        }

        let { estateSn, force } = action;

        let updated;
        if (force) {
            if (!state.estate || !state.estate.updated) {
                updated = 1;
            } else {
                updated = state.estate.updated + 1;
            }
        } else {
            updated = state.estate !== null ? state.estate.updated : 0;
        }

        return {
            ...state,
            estate: {
                ...state.estate,
                estateSn,
                updated,
            },
        };
    },
    SELECT_FEATURE: (state, action) => {
        let target = action.target;
        let feature = {
            ...state.feature,
            estateSn: target.estateSn,
            firmSn: target.firmSn,
            centered: null,
        };

        if (target.forceUpdate) {
            feature.forceUpdate += 1;
        }

        return {
            ...state,
            feature: feature,
        };
    },
    UNSELECT_FEATURE: (state, action) => {
        return {
            ...state,
            feature: {
                ...state.feature,
                estateSn: null,
                firmSn: null,
                centered: null,
            },
        };
    },
    SELECT_GEOPOINT: (state, action) => {
        const { firmSn, indicatorNo, targetNo } = action;
        let feature;
        if (!state.feature.lockedFirmSn) {
            // 企业窗格没有锁定，选择企业
            feature = {
                ...state.feature,
                firmSn: firmSn,
            };
        } else {
            feature = state.feature;
        }
        return {
            ...state,
            feature: feature,
            geopoint: {
                ...state.geopoint,
                firmSn,
                indicatorNo,
                targetNo,
            },
        };
    },
    UNSELECT_GEOPOINT: (state, action) => {
        let feature;
        if (!state.feature.lockedFirmSn && !state.feature.estateSn) {
            // 企业窗格没有锁定，且没有地块选择
            feature = {
                ...state.feature,
                firmSn: null,
            };
        } else {
            feature = state.feature;
        }

        return {
            ...state,
            feature: feature,
            geopoint: {
                ...state.geopoint,
                firmSn: null,
                indicatorNo: null,
                targetNo: null,
            },
        };
    },
    SET_HEATMAP_INDICATOR: (state, action) => {
        const { indicator } = action;
        return {
            ...state,
            heatmap: {
                ...state.heatmap,
                indicator,
            },
        };
    },
};

const initialState = {
    open: false,
    heatmap: {},
    estate: null,
    feature: {
        estateSn: null,
        firmSn: null,
        lockedFirmSn: null,
        forceUpdate: 0,
    },
    geopoint: {
        firmSn: null,
    },
};
const reducer = (editorState, action) => {
    console.log("action: ", action, editorState);

    const actionHandler = actions[action.type];
    if (actionHandler) {
        return actionHandler(editorState, action);
    }

    console.warn(`unkown action ${action.type}`);
    return editorState;
};

export default function useStateDispatcher() {
    return useReducer(reducer, initialState);
}
