import React from "react";
import styled from "styled-components";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import { dateago } from "../../util/datetime";
import { Link } from "../../util/cardpage";

export default React.forwardRef(({ className, records }, ref) => {
    return (
        <StyledDiv ref={ref} className={`${className} firm-info-grid`}>
            <TableContainer>
                <Table stickyHeader dense="true">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">企业名称</TableCell>
                            <TableCell align="left" style={{ width: "20em" }}>
                                统一社会信用代码
                            </TableCell>
                            <TableCell align="left" style={{ width: "8em" }}>
                                运行状态
                            </TableCell>
                            <TableCell align="left" style={{ width: "8em" }}>
                                更新时间
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {records.map((item, idx) => (
                            <TableRow key={item.firm_sn}>
                                <TableCell className="firm_name" align="left">
                                    <Link to={`/datamngt/firm/${item.firm_sn}/profile`}>
                                        {item.firm_name}
                                    </Link>
                                </TableCell>
                                <TableCell align="left">
                                    <span className="usci_code">
                                        {item.usci}
                                    </span>
                                    <span className="usci_seqno">
                                        {item.usci_seqno?.length > 0 &&
                                            `(${item.usci_seqno})`}
                                    </span>
                                </TableCell>
                                <TableCell align="left">
                                    {item.firm_state}
                                </TableCell>
                                <TableCell align="left">
                                    {dateago(new Date(item.rev_time))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </StyledDiv>
    );
});

const StyledDiv = styled.div`
    .MuiTableCell-root {
        padding: 8px 16px;
    }

    td.firm_name {
        font-size: 1.2em;
        line-height: 1.3;
        > a,
        a:visited,
        a:active {
            color: hsl(205deg 92% 25%);
            text-decoration: none;
        }
    }
`;
