import React, { useState, useImperativeHandle } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { useForm, Controller } from "react-hook-form";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import clsx from "clsx";
import AsyncButton from "../../util/panel/AsyncButton";
import { putJsonData, postJsonData } from "../../util/json";

function FirmInfoEditDialog({ onDone }, ref) {
    const [open, setOpen] = useState(false);
    const [firm, setFirm] = useState(null);
    const {
        control,
        reset,
        formState,
        errors,
        trigger: triggerValidation,
        getValues,
    } = useForm({ mode: "onBlur" });
    const { isDirty } = formState;

    useImperativeHandle(
        ref,
        () => ({
            new: () => {
                reset({});
                setFirm(null);
                setOpen(true);
            },
            edit: (firm) => {
                // 每次打开对话框，重置表单值
                if (firm) {
                    reset(firm);
                } else {
                    reset({});
                }

                setFirm(firm);
                setOpen(true);
            },
        }),
        [reset]
    );

    const handleClose = () => {
        setFirm(null);
        // setHandleDone(null);
        setOpen(false);
    };

    const handleCommit = async () => {
        if (!(await triggerValidation())) {
            console.log("error", errors);
            return;
        }

        const fields = getValues();

        const data = firm ? { ...firm } : {};
        data.firm_name = fields.firm_name;
        data.usci = fields.usci;
        data.usci_seqno = fields.usci_seqno;
        data.rev_notes = fields.rev_notes;

        let result;
        if (firm) {
            result = await putJsonData(
                `/api/firm/${firm.firm_sn}/profile`,
                data
            );
        } else {
            result = await postJsonData(`/api/firm/new/profile`, data);
        }

        if (onDone) {
            onDone(result);
        }

        handleClose();
    };

    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                {firm === null ? "登记新企业" : "编辑企业信息"}
            </DialogTitle>
            <DialogContent>
                <form>
                    <Controller
                        control={control}
                        as={TextField}
                        label="企业名称"
                        name="firm_name"
                        defaultValue=""
                        rules={{
                            required: "必填",
                        }}
                        error={Boolean(errors.firm_name)}
                        helperText={
                            errors.firm_name ? errors.firm_name.message : " "
                        }
                    />

                    <div className="usci-fields">
                        <Controller
                            className="field-usci"
                            control={control}
                            as={TextField}
                            label="社会统一信用代码"
                            name="usci"
                            defaultValue=""
                        />
                        <Controller
                            className="field-usci-seqno"
                            control={control}
                            as={TextField}
                            label="序号"
                            name="usci_seqno"
                            defaultValue=""
                        />
                    </div>

                    <hr />
                    <Controller
                        control={control}
                        as={TextField}
                        label="变更备注"
                        name="rev_notes"
                        defaultValue=""
                        rules={{
                            required: "必填",
                        }}
                        error={Boolean(errors.rev_notes)}
                        helperText={
                            errors.rev_notes ? errors.rev_notes.message : " "
                        }
                    />
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    取消
                </Button>
                <AsyncButton
                    onAction={handleCommit}
                    color="primary"
                    disabled={!isDirty}
                >
                    提交修改
                </AsyncButton>
            </DialogActions>
        </StyledDialog>
    );
}

export default React.forwardRef(FirmInfoEditDialog);

const StyledDialog = styled(Dialog)`
    .MuiDialogTitle-root {
        user-select: none;
    }

    form {
        min-width: 30em;

        display: flex;
        flex-direction: column;

        .MuiTextField-root {
            min-height: 4.5em;
        }

        /* > div {
            margin-top: 1em;
        } */

        .usci-fields {
            display: flex;
            .field-usci {
                flex: 1 1;
            }

            .field-usci-seqno {
                margin-left: 1em;
                flex: 0 0 5em;
            }
        }

        .content {
        }
    }
`;
