import React, { useState, useRef, useEffect } from "react";
import { useCallback, useMemo } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import { Page, RibbonHeader, PageBody } from "../../util/cardpage";
import { useReturnPath, useRedirectInPage } from "../../util/cardpage";
import { useGetJson } from "../../util/json";
import FirmMapView from "../map/FirmMapView";
import FirmInfo from "./FirmInfo";
import FirmLotItemList from "./FirmLotItemList";
import FirmRemoveDialog from "./FirmRemoveDialog";
import FirmIndicators from "./FirmIndicators";

import { useParams } from "react-router-dom";

const tabs = ["profile", "indicators"];

function useTabs() {
    const redirectInPage = useRedirectInPage();
    const { firmSn, tabId } = useParams();

    return useMemo(() => {
        let tabIdx;
        if (tabId === "profile") {
            tabIdx = 0;
        } else if (tabId === "indicators") {
            tabIdx = 1;
        } else {
            tabIdx = 0;
            redirectInPage(`/datamngt/firm/${firmSn}/${tabs[tabIdx]}`);
        }

        const setTabIdx = (idx) => {
            const tabId = tabs[idx];
            redirectInPage(`/datamngt/firm/${firmSn}/${tabId}`);
        };

        return { firmSn, tabIdx, setTabIdx };
    }, [firmSn, tabId, redirectInPage]);
}

export default function FirmSummary({ className }) {
    const mapRef = useRef(null);
    const removeDialogRef = useRef(null);
    const { firmSn, tabIdx, setTabIdx } = useTabs();
    const [firm, setFirm] = useState(null);
    const goback = useReturnPath();

    return (
        <StyledPage className={className}>
            <RibbonHeader
                defaultReturnPath="/datamngt"
                title={firm ? firm.firm_name : "加载中..."}
                middle={<FirmTabs tabIdx={tabIdx} setTabIdx={setTabIdx} />}
                extra={
                    <Button
                        variant="contained"
                        color="default"
                        onClick={() => {
                            removeDialogRef.current.removeFirm(
                                { firmSn },
                                (firm) => {
                                    setTimeout(() => {
                                        goback();
                                    });
                                }
                            );
                        }}
                    >
                        删除企业
                    </Button>
                }
            />
            <PageBody>
                {tabIdx === 0 && (
                    <FirmProfile
                        mapRef={mapRef}
                        firmSn={firmSn}
                        onFirmChange={(firm) => setFirm(firm)}
                    />
                )}
                {tabIdx === 1 && <FirmIndicators firmSn={firmSn} />}
            </PageBody>
            <FirmRemoveDialog ref={removeDialogRef} />
        </StyledPage>
    );
}

function FirmTabs({ tabIdx, setTabIdx }) {
    return (
        <Tabs
            value={tabIdx}
            onChange={(event, newValue) => {
                setTabIdx(newValue);
            }}
            indicatorColor="primary"
            textColor="primary"
        >
            <Tab label="基本信息" />
            <Tab label="普查数据指标" />
        </Tabs>
    );
}

function FirmProfile({ mapRef, firmSn, onFirmChange }) {
    const { data: firm, mutate: mutateFirm } = useGetJson(
        `/api/firm/${firmSn}/profile`
    );

    useEffect(() => {
        onFirmChange && onFirmChange(firm ? firm : null);
    }, [firm, onFirmChange]);

    const [candidateShape, setCandidateShape] = useState(null);
    const handleLotShapeSelect = useCallback((target) => {
        setCandidateShape(target.selected ? target.shapeSn : null);
    }, []);

    return (
        <div className="profile-pane">
            <div className="left-pane">
                <FirmInfo firm={firm} mutateFirm={mutateFirm} />
                <FirmLotItemList
                    mapRef={mapRef}
                    firmSn={firmSn}
                    candidateShapeSn={candidateShape}
                />
            </div>
            <div className="right-pane">
                <FirmMapView
                    ref={mapRef}
                    firmSn={firmSn}
                    onLotShapeSelect={handleLotShapeSelect}
                />
            </div>
        </div>
    );
}

const StyledPage = styled(Page)`
    display: flex;
    flex-direction: column;

    .page-header-title {
        > .title {
            font-size: 1.5rem;
            margin-right: 0.5rem;
        }
    }

    .firm-info {
        .usci-field {
            > label {
                font-size: 0.8em;
            }

            > .code {
                font-size: 1.1em;
                font-weight: bold;

                > .seqno {
                    margin-left: 1em;
                }
            }
        }

        .ctrl-btns {
            display: flex;
            justify-content: flex-end;
        }
    }

    .profile-pane {
        height: 100%;
        width: 100%;

        display: flex;
        flex-direction: row;

        > .left-pane {
            flex: 1 1 10em;
        }

        > .right-pane {
            flex: 2 2;
        }
    }

    .page-header-middle {
        justify-content: flex-start;
        margin-left: 2em;
    }
`;
