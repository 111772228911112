import { useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { createLocation } from "history";

export default function useRedirectInPage() {
    const location = useLocation();
    const history = useHistory();

    return useCallback(
        (to) => {
            console.log(to, typeof to);
            if (typeof to === "string") {
                to = createLocation(to);
            }
            const newLoction = {
                ...location,
                pathname: to.pathname,
                search: to.search,
                hash: to.hash,
            };

            history.push(newLoction);
        },
        [location, history]
    );
}
