import React, { useState, useImperativeHandle } from "react";
import styled from "styled-components";
// import Button from "@material-ui/core/Button";
// import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import { postJsonData, deleteResource } from "../../util/json";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import clsx from "clsx";

import LotHistory from "./LotHistory";

// import AsyncButton from "../../util/panel/AsyncButton";

export default React.forwardRef(({ onCommit }, ref) => {
    const [firmSn, setFirmSn] = useState(null);

    useImperativeHandle(
        ref,
        () => ({
            showHistory: (firmSn) => {
                console.log("show history:", firmSn);
                setFirmSn(firmSn);
            },
        }),
        []
    );

    const handleClose = () => {
        setFirmSn(null);
    };

    if (firmSn === null) {
        return null;
    }

    return (
        <StyledDialog
            open={firmSn !== null}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle disableTypography>
                <h2>企业地块变更历史</h2>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <LotHistory firmSn={firmSn} />
            </DialogContent>
            {/* <DialogActions>
                <Button onClick={handleClose} color="primary">
                    确定
                </Button>
            </DialogActions> */}
        </StyledDialog>
    );
});

const StyledDialog = styled(Dialog)`
    .MuiDialogTitle-root {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .revisions {
        .MuiTimelineItem-oppositeContent {
            flex: 0 8em;
        }

        .item {
            display: flex;

            label.shape-sn {
                background-color: hsl(344 54% 31%);
                padding: 2px 5px;
                color: #fff;
                margin: 0 3px;
                border-radius: 2px;
            }
        }
    }
`;
