import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Style } from "ol/style";
import { Fill, Stroke } from "ol/style";
import { distance as coordinateDistance } from "ol/coordinate";
import RegularShape from "ol/style/RegularShape";

export default class SelectionLayer extends VectorLayer {
    constructor() {
        super({
            source: new VectorSource(),
            style: selectionStyleFunc
        });

        this._sketchPoint = null;
        this._selectedFeature = null;
        this._selectedSourcePoint = null;
        this._sourcePointExtents = {};
        this._sourcePoints = {};
        this._hoverSourcePoint = null;
        this._controlPoints = {};

        this._pointFeatures = [];
        this._sourceFeatures = [];
        this._targetFeatures = [];
        this._focus = null;

        this._onPicked = null;
    }

    setOnPicked(callback) {
        this._onPicked = callback;
    }

    setMap(map) {
        this._map = map;
    }

    setAttachedLayer(layer) {
        this._attachedLayer = layer;
    }

    handleMouseMoveEvent(event) {
        if (!this._focus) {
            this._updateSketchPoint(null);
            return;
        }

        const { coordinate } = event;

        const focusType = this._focus.type;
        if (focusType === "source") {
            this._handleMouseMoveEventOnSourceMode(coordinate);
        } else if (focusType === "target") {
            this._updateSketchPoint(coordinate);
        } else {
            this._updateSketchPoint(null);
        }
    }

    _handleMouseMoveEventOnSourceMode(coordinate) {
        const pixel = this._map.getPixelFromCoordinate(coordinate);

        // // 识别控制源点是否在悬浮状态
        // let sourceFeature = null;
        // this._map.forEachFeatureAtPixel(pixel, feature => {
        //     if (feature.get("type") === "Source") {
        //         sourceFeature = feature;
        //         return true;
        //     }
        // });
        // if (sourceFeature) {
        //     // 悬浮在控制源点之上
        //     if (this._hoverSourcePoint === null) {
        //         sourceFeature.set("selected", true);
        //         this._hoverSourcePoint = sourceFeature;
        //         this._updateSketchPoint(null); // 取消画笔点
        //         return;
        //     } else {
        //         this._updateSketchPoint(null); // 取消画笔点
        //         return;
        //     }
        // } else {
        //     if (this._hoverSourcePoint !== null) {
        //         this._hoverSourcePoint.set("selected", false);
        //         this._hoverSourcePoint = null;
        //     }
        // }

        if (this._selectedFeature) {
            // 锁定源图形，鼠标自动贴近图形上的点
            let closestCoord = this._selectedFeature
                .getGeometry()
                .getClosestPoint(coordinate);

            const closestPixel = this._map.getPixelFromCoordinate(closestCoord);

            if (coordinateDistance(pixel, closestPixel) < 20) {
                this._updateSketchPoint(closestCoord);
                return;
            }
        }

        this._updateSketchPoint(null);
    }

    handleClickEvent(event) {
        // 处理点击事件
        let { stack } = event;
        if (stack.length === 0) return;

        if (this._focus === null) return; // 只有选择控制点后才能锁定图形、拾取坐标

        if (this._focus.type === "source") {
            // 当选择控制点源点时才需要锁定图形，并在图形上拾取坐标

            let { feature, layer } = stack[0];
            if (layer !== this) {
                // 点击其它图层的图形，锁定该图形
                this._lockShapeFeature(feature);
                return;
            }

            if (!this._selectedFeature) {
                return;
            }

            const featureType = feature.get("type");
            if (featureType === "SketchPoint" && this._onPicked) {
                // 控制点源点拾取坐标
                let coordinate = feature.getGeometry().getCoordinates();

                const shape_sn = this._selectedFeature.get("shape_sn");
                this._onPicked({ ...this._focus, shape_sn, coordinate });
                return;
            }

            if (this._selectedFeature) {
                // 重复点击同一图形第二次取消锁定
                this._unlockShapeFeature();
            }
        } else if (this._focus.type === "target") {
            let coordinate = this._map.getCoordinateFromPixel(event.pixel);
            this._onPicked({ ...this._focus, coordinate });
        }
    }

    getShapeFeature(feature) {
        const coordinate = feature.getGeometry().getCoordinates();
        const shapeSn = feature.get("attachedShapeSn");

        if (!this._attachedLayer) return;
        let features = this._attachedLayer
            .getSource()
            .getFeaturesAtCoordinate(coordinate);

        for (let feature of features) {
            console.log(2222, shapeSn, feature);
            if (feature.get("shape_sn") === shapeSn) {
                return feature;
            }
        }

        return null;
    }

    _lockShapeFeature(feature) {
        console.log("lock shape: ", feature);
        const source = this.getSource();
        if (this._selectedFeature) {
            source.removeFeature(this._selectedFeature);
        }

        this._selectedFeature = feature;
        source.addFeature(feature);

        // affineTransform2D(feature.getGeometry(), [1, 0, 0, 1, 50, 50]);

        // console.log("transform: ", feature.getGeometry().applyTransform);
    }

    _unlockShapeFeature() {
        if (this._selectedFeature === null) return;

        this.getSource().removeFeature(this._selectedFeature);
        this._selectedFeature = null;
    }

    _lockPointFeature(type, index) {
        if (type === "source") {
            let feature = this._sourceFeatures[index];
            if (feature) {
                feature.set("focused", true);
                if (this._selectedFeature === null) {
                    // 锁定控制点时，同时锁定源点对应的图形
                    const shapeFeature = this.getShapeFeature(feature);
                    if (shapeFeature) {
                        this._lockShapeFeature(shapeFeature);
                    }
                }
            }
        } else if (type === "target") {
            let feature = this._targetFeatures[index];
            if (feature) {
                feature.set("focused", true);
            }
        }

        this._focus = { type, index };
    }

    _unlockPointFeature(type, index) {
        console.log("unlock point feature", index);
        // 取消控制点锁定
        if (type === "source") {
            let feature = this._sourceFeatures[index];
            if (feature) {
                feature.set("focused", false);
            }
        } else if (type === "target") {
            let feature = this._targetFeatures[index];
            if (feature) {
                feature.set("focused", false);
            }
        }

        this._unlockShapeFeature();
        this._focus = null;
    }

    setFocus(focus) {
        if (!focus) {
            this._unlockShapeFeature();
            if (this._focus) {
                // 从一个已锁定的控制点切换到新的锁定点
                this._unlockPointFeature(this._focus.type, this._focus.index);
            }

            return;
        }

        const _focus = this._focus;
        const { type, index, focused } = focus;
        if (focused) {
            if (_focus && (_focus.type !== type || _focus.index !== index)) {
                // 从一个已锁定的控制点切换到新的锁定点
                this._unlockPointFeature(_focus.type, _focus.index);
            }
            this._lockPointFeature(type, index);
        } else {
            this._unlockPointFeature(type, index);
        }
    }

    setPoints(points) {
        // console.log("set_Points", points);

        const source = this.getSource();
        for (let feature of this._sourceFeatures) {
            if (feature) {
                source.removeFeature(feature);
            }
        }
        for (let feature of this._targetFeatures) {
            if (feature) {
                source.removeFeature(feature);
            }
        }

        // 更新一组控制点
        this._sourceFeatures = new Array(points.length);
        this._targetFeatures = new Array(points.length);

        for (let idx = 0; idx < points.length; idx++) {
            const p = points[idx];

            let focused;
            const _focus = this._focus;
            focused =
                _focus && _focus.type === "source" && _focus.index === idx;

            console.log("set_points focus", idx, _focus);

            let feature;
            feature = this.createPointFeature("Source", idx, focused, p.source);
            if (p.source) {
                feature.set("attachedShapeSn", p.shape_sn);
            }
            this._sourceFeatures[idx] = feature;

            focused =
                _focus && _focus.type === "target" && _focus.index === idx;

            feature = this.createPointFeature("Target", idx, focused, p.target);
            this._targetFeatures[idx] = feature;
        }
    }

    createPointFeature(type, index, focused, coordinate) {
        if (!coordinate) return null;

        const feature = new Feature(new Point(coordinate));
        feature.set("type", type);
        feature.set("focused", focused);
        feature.set("index", index);
        this.getSource().addFeature(feature);

        return feature;
    }

    _updateSketchPoint(coordinate) {
        if (coordinate === null) {
            if (this._sketchPoint === null) return;

            this._sketchPoint.set("show", false);
            // this._map.getTarget().style.cursor = 'pointer';
            return;
        }

        const coordinates = coordinate.slice();
        if (this._sketchPoint === null) {
            const feature = new Feature(new Point(coordinates));
            feature.set("type", "SketchPoint");
            feature.set("show", true);
            this.getSource().addFeature(feature);
            this._sketchPoint = feature;
            // this._map.getTarget().style.cursor = 'none';
        } else {
            const feature = this._sketchPoint;
            feature.set("show", true);
            // this._map.getTarget().style.cursor = 'none';
            feature.getGeometry().setCoordinates(coordinates);
        }
    }
}


function selectionStyleFunc(feature) {
    let type = feature.get("type");
    if (type === "SketchPoint") {
        if (feature.get("show") === true) {
            return sketchPointStyle;
        } else {
            return null;
        }
    }

    if (type === "Source" || type === "Target") {
        if (feature.get("focused") === true) {
            return selectedSourcePointStyle;
        } else {
            return SourcePointStyle;
        }
    }

    return new Style({
        stroke: new Stroke({
            color: "#fc3",
            width: 1
        }),
        fill: new Fill({
            color: "rgba(255,0,0,0.1)"
        })
    });
}

const sketchPointStyle = [
    new Style({
        image: new RegularShape({
            fill: new Fill({ color: "hsla(0, 0%, 100%, 0)" }),
            stroke: new Stroke({
                color: "hsla(120, 53%, 49%, 0.62)",
                width: 1
            }),
            points: 4,
            radius: 32,
            radius2: 0,
            angle: 0
        })
    }),
    new Style({
        // square
        image: new RegularShape({
            fill: new Fill({ color: "hsla(0, 0%, 100%, 0.2)" }),
            stroke: new Stroke({
                color: "hsla(194, 70%, 48%, 0.65)",
                width: 2
            }),
            points: 4,
            radius: 16,
            angle: Math.PI / 4
        })
    })
];

const SourcePointStyle = [
    new Style({
        image: new RegularShape({
            fill: new Fill({ color: "hsla(0, 0%, 100%, 0)" }),
            stroke: new Stroke({
                color: "hsla(120, 53%, 49%, 0.62)",
                width: 1
            }),
            points: 4,
            radius: 16,
            radius2: 0,
            angle: 0
        })
    }),
    new Style({
        // square
        image: new RegularShape({
            fill: new Fill({ color: "hsla(0, 0%, 100%, 0.2)" }),
            stroke: new Stroke({
                color: "hsla(120, 70%, 48%, 0.65)",
                width: 2
            }),
            points: 4,
            radius: 8,
            angle: Math.PI / 4
        })
    })
];

const selectedSourcePointStyle = [
    new Style({
        image: new RegularShape({
            fill: new Fill({ color: "hsla(0, 0%, 100%, 0)" }),
            stroke: new Stroke({
                color: "hsla(0, 100%, 50%, 0.65)",
                width: 1
            }),
            points: 4,
            radius: 16,
            radius2: 0,
            angle: 0
        })
    }),
    new Style({
        // square
        image: new RegularShape({
            fill: new Fill({ color: "hsla(0, 100%, 50%, 0.2)" }),
            stroke: new Stroke({
                color: "hsla(0, 100%, 50%, 0.65)",
                width: 2
            }),
            points: 4,
            radius: 8,
            angle: Math.PI / 4
        })
    })
];
