import React from "react";
import clsx from "clsx";

import styled from "styled-components";

import Loading from "./panel/BouncingLoading";
import LoadingError from "./panel/LoadingError";
import { ReactComponent as EmptyIcon } from "./panel/emptybox.svg";

export default function ActionCurtain(props) {
    const { action, children } = props;

    if (!action) {
        return children !== undefined ? children : null;
    }

    const { error, processing, busy, result } = action;

    if (error) {
        return (
            <StyledDiv className="action-fence error">
                <LoadingError error={castError(error)} redo={action.run} />
            </StyledDiv>
        );
    }

    if (busy || processing) {
        return (
            <StyledDiv className="action-fence processing">
                <Loading />
            </StyledDiv>
        );
    }

    if (result === null || result === undefined) {
        return (
            <StyledDiv className={clsx("action-fence empty")}>
                <EmptyIcon />
            </StyledDiv>
        );
    }

    return children !== undefined ? children : null;
}

const StyledDiv = styled.div`
    width: 100%;
    min-height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.empty > img {
        min-width: 64px;
        width: 20%;
        height: 50%;
    }
`;

function castError(e) {
    console.error(e);
    if (e.message) {
        console.log(111, e.response);
        return {
            message: `${e.toString()}`,
            detail: e.message
        };
    }

    return {
        message: "发生错误"
    };
}
