import React from "react";
import styled from "styled-components";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { useGetJson } from "../../util/json";

export default ({ firmSn }) => {
    const { data: indicators } = useGetJson(`/api/firm/${firmSn}/indicators`);

    if (indicators === undefined) {
        return <StyledDiv className={`indicator-list`}></StyledDiv>;
    }

    return (
        <StyledDiv className={`indicator-list`}>
            <TableContainer>
                <Table stickyHeader dense="true">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ width: "10em" }}>
                                指标对象类别
                            </TableCell>
                            <TableCell align="left" style={{ width: "20em" }}>
                                指标名称
                            </TableCell>
                            <TableCell align="left" style={{ width: "8em" }}>
                                单位
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {indicators.map((item, idx) => (
                            <TableRow key={idx}>
                                <TableCell className="part" align="left">
                                    {item.part}
                                </TableCell>
                                <TableCell align="left">
                                    {item.indicator}
                                </TableCell>
                                <TableCell align="left">{item.unit}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </StyledDiv>
    );
};

const StyledDiv = styled.div`
    height: 100%;

    display: flex;

    .MuiTableContainer-root {
        /* height: 100%; */
        /* height: calc(100vh - 3em - 8em); */
    }

    .MuiTableCell-root {
        padding: 8px 16px;
    }

    td.firm_name {
        font-size: 1.2em;
        line-height: 1.3;
        > a,
        a:visited,
        a:active {
            color: hsl(205deg 92% 25%);
            text-decoration: none;
        }
    }
`;
