import Pbf from "pbf";
import geobuf from "geobuf";
import GeoJSON from "ol/format/GeoJSON";

const geojsonFormat = new GeoJSON();

export async function fetchGeoJsonFeatures(url) {
    const response = await fetch(url);
    if (!response.ok) {
        console.error(`fetch ${response.status}: ${response.statusText}`);
    }

    let buffer = await response.arrayBuffer();
    if (buffer.byteLength === 0) {
        return [];
    }

    const geojson = geobuf.decode(new Pbf(buffer));
    if (!geojson || !geojson.features) {
        console.error("加载无效的GeoJSON");
        return [];
    }

    return geojsonFormat.readFeatures(geojson);
}