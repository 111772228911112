import proj4 from "proj4";
import { register } from "ol/proj/proj4";
import {
    get as getProjection,
    // transform as ol_transform,
    getTransform
    // fromLonLat as ol_fromLonLat
} from "ol/proj";

proj4.defs("EPSG:4490", "+proj=longlat +ellps=GRS80 +no_defs");
proj4.defs(
    "EPSG:4548",
    "+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs"
);

proj4.defs(
    "EPSG:4526",
    "+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=38500000 +y_0=0 +ellps=GRS80 +units=m +no_defs"
);

proj4.defs(
    "EPSG:4527",
    "+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=39500000 +y_0=0 +ellps=GRS80 +units=m +no_defs"
);
proj4.defs(
    "EPSG:2363",
    "+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=39500000 +y_0=0 +a=6378140 +b=6356755.288157528 +units=m +no_defs"
);

proj4.defs(
    "EPSG:2415", // Beijing 1954 / 3-degree Gauss-Kruger zone 39
    "+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=39500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs"
);

proj4.defs(
    "EPSG:2416",
    "+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=40500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs"
);

proj4.defs(
    "EPSG:4775",
    "+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=39500000 +y_0=0 +ellps=krass +units=m +no_defs"
);

proj4.defs(
    "EPSG:4776",
    "+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=40500000 +y_0=0 +ellps=krass +units=m +no_defs"
);

proj4.defs(
    "EPSG:4796", // New Beijing / 3-degree Gauss-Kruger CM 117E
    "+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs"
);

register(proj4);

getProjection("EPSG:4490").setExtent([-180, -90, 180, 90]);
// 天地图采用WGS84的BoundingBox， [-180, -90, 180, 90]
// http://t0.tianditu.gov.cn/img_w/wmts?request=GetCapabilities&service=wmts

// export const defaultSRID = "EPSG:4548";
export const defaultSRID = "EPSG:4527";
export const fromLonLat = getTransform("EPSG:4490", defaultSRID);
export const toLonLat = getTransform(defaultSRID, "EPSG:4490");
export { getProjection, getTransform };
