import React from "react";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import MapList from "./MapList";
import Calibration from "./Calibration";

export default function ControlPane(props) {
    const { state, dispatch } = props;

    // const mapName = state.map.name;
    return (
        <Styled>
            <Switch>
                <Route
                    path="/calibrator/map/list"
                    render={() => (
                        <MapList map={state.map} dispatch={dispatch} />
                    )}
                />
                <Route
                    path="/calibrator/map/:mapName"
                    render={() => (
                        <Calibration
                            focus={state.focus}
                            map={state.map}
                            dispatch={dispatch}
                        />
                    )}
                />
            </Switch>
        </Styled>
    );
}

const Styled = styled.div`
    top: 1em;

    position: absolute;
    right: 0;
    width: 23em;

    background-color: #fff;
    box-shadow: 1px 1px 4px hsla(0, 0%, 40%, 0.5);
`;
