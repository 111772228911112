import Worker from "./GeopointFetcher.worker";
import GeoJSON from "ol/format/GeoJSON";

const geojsonFormat = new GeoJSON();

class GeojsonFetcher {
    constructor(olmap) {
        this._olmap = olmap;
        this._jobId = 1;
        this._jobs = {};

        this._worker = new Worker();
        this._worker.addEventListener("message", event => {
            let { jobId, geojson, error } = event.data;

            const job = this._jobs[jobId];
            try {
                if (!error) {
                    let features = geojsonFormat.readFeatures(geojson);
                    job.resolve(features);
                } else {
                    job.reject(error);
                }
            } finally {
                delete this._jobs[jobId];
            }
        });
    }

    fetch(url, settings) {
        const jobId = this._jobId;
        this._jobId += 1;

        const promise = new Promise((resolve, reject) => {
            this._jobs[jobId] = {
                jobId,
                resolve,
                reject
            };

            this._worker.postMessage({ jobId, url, settings });
        });

        return promise;
    }
}

const fetcher = new GeojsonFetcher();

export function fetchFeatures(url, settings) {
    url = window.location.origin + url;
    return fetcher.fetch(url, settings);
}
