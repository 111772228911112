import React from "react";
import styled from "styled-components";

// import { Icon } from "antd";

// import clsx from '../util/clsx';
import { useUserSession } from "../session/UserContext";

export default function UserTitle(props) {
    const { className } = props;
    const session = useUserSession();
    return (
        <Styled className={className}>
            {/* <Icon className="icon" type="user" /> */}
            <div className="name"> {session.name} </div>
            <div className="title"> {session.title} </div>
        </Styled>
    );
}

const Styled = styled.div`
    display: flex;
    align-items: baseline;
    font-size: 110%;
    line-height: 1.6;

    > .icon {
        font-size: 110%;
        align-self: center;
        margin-top: 2px;
    }

    > .name {
        font-size: 110%;
        margin-left: 5px;
        margin-right: 5px;
    }

    > .title {
        font-size: 85%;
        background-color: hsla(0, 0%, 98%, 0.15);
        border-radius: 5px;
        padding: 0 4px;
    }
`;
