import React from "react";
import { createLocation } from "history";

import { Link } from "react-router-dom";

export default function ({ to, ...props }) {
    if (typeof to === "string") {
        to = createLocation(to);
    }

    return (
        <Link
            to={(location) => {
                const loc = {
                    ...location,
                    pathname: to.pathname,
                    search: to.search,
                    hash: to.hash,
                };

                const referer = `${location.pathname}${location.search}${location.hash}`;
                loc.state = loc.state
                    ? { ...loc.state, referer }
                    : { referer };

                return loc;
            }}
            {...props}
        />
    );
}
