import React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { createPopper } from "@popperjs/core";

export default function EstateAnnotation({ mapRef, controlRef }) {
    const annotationRef = useRef(null);
    const contentRef = useRef(null);
    const popperRef = useRef(null);
    const virtualElementRef = useRef(null);
    const [line, setLine] = useState(null);
    const [event, setEvent] = useState(null);

    // console.log("map: ", mapRef);

    useEffect(() => {
        if (!annotationRef.current) return;
        const virtualElement = {
            getBoundingClientRect: generateGetBoundingClientRect(),
        };

        let instance = createPopper(virtualElement, annotationRef.current);
        virtualElementRef.current = virtualElement;
        popperRef.current = instance;
    }, []);

    useEffect(() => {
        // 创建供外部使用的控制对象，方便更新批注内容
        controlRef.current = {
            update: ({ pixel, firms, hover }) => {
                if (!hover) {
                    setEvent(null);
                    setLine(null);
                    return;
                }

                const {
                    width: mapWidth,
                    height: mapHeight,
                } = mapRef.current.getViewport().getBoundingClientRect();

                const [x0, y0] = pixel;
                let part, placement;
                if (y0 < mapHeight / 3) {
                    if (x0 < mapWidth / 2) {
                        part = "TL";
                        placement = "right-start";
                    } else {
                        part = "TR";
                        placement = "left-start";
                    }
                } else {
                    if (x0 < mapWidth / 2) {
                        part = "BL";
                        placement = "right-end";
                    } else {
                        part = "BR";
                        placement = "left-end";
                    }
                }

                setEvent({ pixel, firms, part, placement });
            },
        };

        return () => {
            controlRef.current = null;
        };
    }, [mapRef, controlRef]);

    useEffect(() => {
        if (event === null) {
            return;
        }

        if (!popperRef.current) {
            console.warn("null: ", popperRef.current);
            return;
        }

        const [x, y] = event.pixel;
        const rect = generateGetBoundingClientRect(x, y);
        virtualElementRef.current.getBoundingClientRect = rect;

        popperRef.current.setOptions({
            placement: event.placement,
        });
        // popperRef.current.update();

        const { part } = event;
        const { width, height } = annotationRef.current.getBoundingClientRect();

        const {
            width: contentWidth,
            height: contentHeight,
        } = contentRef.current.getBoundingClientRect();

        // Popper更新后再重新绘制批注线
        setLine({ part, width, height, contentWidth, contentHeight });
    }, [event]);

    let annotationCls = "annotation";
    let contentCls = "content";
    if (event === null) {
        annotationCls += " hidden";
    } else {
        contentCls += " " + event.part;
    }

    return (
        <Styled ref={annotationRef} className={annotationCls}>
            <div className={contentCls} ref={contentRef}>
                {event &&
                    event.firms.map((firm) => (
                        <div key={firm.firm_sn} className="caption">
                            {firm.firm_name}
                        </div>
                    ))}
            </div>
            {line && <AnnotationLine line={line} />}
        </Styled>
    );
}

function AnnotationLine({ line }) {
    const { part, width, height, contentWidth, contentHeight } = line;

    // console.log("line: ", [width, height], [contentWidth, contentHeight]);

    let path;
    if (part[0] === "T") {
        if (part[1] === "L") {
            // 向上偏移2像素，保持完全显示批注线宽度
            path = `M${width} ${height - 2} `;
            path += `h${-contentWidth}`;
            path += `L 0 0`;
        } else {
            path = `M0 ${height - 2} h${contentWidth} L${width} 0`;
        }
    } else {
        if (part[1] === "L") {
            path = `M${width} ${contentHeight - 2} `;
            path += `h${-contentWidth}`;
            path += `L 0 ${height}`;
        } else {
            path = `M0 ${contentHeight - 2} `;
            path += `h${contentWidth} `;
            path += `L${width} ${height}`;
        }
    }

    return (
        <svg viewBox={`0 0 ${width} ${height}`}>
            <path d={path} />
        </svg>
    );
}

const Styled = styled.div`
    position: absolute;
    pointer-events: none;

    &.hidden {
        visibility: hidden;
        pointer-events: none;
    }

    .content {
        min-width: 6em;
        min-height: 1em;
        pointer-events: none;
        background-color: hsla(0, 0%, 100%, 0.21);
        padding: 0px 5px 2px 5px;
        color: hsl(0, 16%, 89%);
        text-shadow: 2px 2px 3px hsla(0, 44%, 17%, 0.8);

        .caption {
            font-weight: bold;
        }

        &.TL {
            margin-top: 20px;
            margin-left: 20px;
        }

        &.TR {
            margin-top: 20px;
            margin-right: 20px;
        }

        &.BL {
            margin-bottom: 20px;
            margin-left: 20px;
        }

        &.BR {
            margin-bottom: 20px;
            margin-right: 20px;
        }
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        path {
            stroke: hsl(0, 100%, 50%);
            stroke-width: 2.5px;
            fill: transparent;

            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            animation: path-dash 800ms linear forwards;
        }
    }

    @keyframes path-dash {
        to {
            stroke-dashoffset: 0;
        }
    }
`;

function generateGetBoundingClientRect(x = 0, y = 0) {
    return () => ({
        width: 0,
        height: 0,
        top: y,
        right: x,
        bottom: y,
        left: x,
    });
}
