import React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import OLMap from "ol/Map";
import OLMapView from "ol/View";
import { ScaleLine } from "ol/control";

import { defaultSRID, fromLonLat } from "../map/projection";
import ZoomButton from "../map/control/ZoomButton";
import MapStatusBar from "../map/control/MapStatusBar";
import TiandituLayer from "../map/layer/TiandituLayer";
import VectorMapLayer from "./VectorMapLayer";
import SelectionLayer from "./SelectionLayer";

import useDispatcher from "./dispatcher";
import MainPane from "./pane/MainPane";
import useMapClick from "./useMapClick";

export default function Calibrator(props) {
    const [state, dispatch] = useDispatcher();
    const mapDivRef = useRef(null);
    const [mapObj, setMapObj] = useState(null);
    const [selectionLayer, setSelectionLayer] = useState(null);

    useEffect(() => {
        if (!mapObj || !state.map.name) return;

        const vectorMapLayer = mapObj.get("Layer_VectorMapLayer");

        vectorMapLayer.loadMap(state.map.name);
    }, [mapObj, state.map.name]);

    useEffect(() => {
        if (!mapDivRef) return;
        const tiandituLayer = new TiandituLayer();
        const vectorMapLayer = new VectorMapLayer();
        const selectionLayer = new SelectionLayer();

        const olmap = new OLMap({
            controls: [new ScaleLine()],
            layers: [tiandituLayer, vectorMapLayer, selectionLayer],
            view: new OLMapView({
                projection: defaultSRID,
                center: fromLonLat([117.52, 39.09]),
                maxZoom: 18,
                minZoom: 2,
                zoom: 10,
            }),
        });
        olmap.set("Layer_VectorMapLayer", vectorMapLayer);
        vectorMapLayer.setMap(olmap);

        olmap.set("selectionLayer", selectionLayer);
        selectionLayer.setMap(olmap);

        selectionLayer.setAttachedLayer(vectorMapLayer);

        olmap.on("pointermove", function (evt) {
            if (evt.dragging) {
                return;
            }
            selectionLayer.handleMouseMoveEvent(evt);
        });

        olmap.setTarget(mapDivRef.current);

        setMapObj(olmap);
        setSelectionLayer(selectionLayer);
    }, []);

    useEffect(() => {
        selectionLayer &&
            selectionLayer.setOnPicked((event) => {
                dispatch({ type: "PICK_COORDINATE", event });
            });
    }, [selectionLayer, dispatch]);

    useEffect(() => {
        selectionLayer && selectionLayer.setFocus(state.focus);
    }, [selectionLayer, state.focus]);

    useEffect(() => {
        selectionLayer && selectionLayer.setPoints(state.map.points);
    }, [selectionLayer, state.map.points]);

    useMapClick(
        mapObj,
        (event) => {
            selectionLayer && selectionLayer.handleClickEvent(event);
        },
        [selectionLayer, dispatch]
    );

    return (
        <Styled>
            <div className="map" ref={mapDivRef} />
            <MapStatusBar mapObj={mapObj} />
            <ZoomButton mapObj={mapObj} />

            <MainPane {...{ state, dispatch }} />
        </Styled>
    );
}

const Styled = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    background-color: #f2f2f2;

    .map-background {
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;

        width: 100%;
        height: 70%;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: -5%;
        left: -5%;
        filter: hue-rotate(300deg);
        opacity: 0.3;
    }

    .page-container {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .page {
            pointer-events: all;
        }
    }

    .map {
        width: 100%;
        height: 100%;
    }
`;
