import React from "react";
import { useEffect, useState } from "react";

import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useAsyncFunction } from "../../util/async";
import ActionCurtain from "../../util/ActionCurtain";

import PointPairList from "./PointPairList";

import axios from "axios";

export default function Calibration(props) {
    const { map, focus, dispatch } = props;

    const [tabIdx, setTabIdx] = useState(0);

    let { mapName } = useParams();
    useEffect(() => {
        if (!mapName) return;
        dispatch({ type: "SELECT_MAP", map_name: mapName });
    }, [mapName, dispatch]);

    let loader = useAsyncFunction(async () => {
        const resp = await axios.get(`/api/calibrator/model/${mapName}`);

        const model = resp.data;
        dispatch({ type: "SAVE", points: model.points });
        dispatch({ type: "FOCUS" });
    }, [mapName]);

    if (loader.busy || loader.error) {
        return (
            <Styled>
                <ActionCurtain action={loader} />
            </Styled>
        );
    }

    return (
        <Styled>
            <div className="pane-header">
                <Link className="home-btn" to="/calibrator/map/list">
                    <NavigateBeforeIcon />
                </Link>
                <label>{mapName}</label>
            </div>
            <Tabs
                value={tabIdx}
                onChange={(event, newValue) => {
                    setTabIdx(newValue);
                }}
            >
                <Tab label="控制点" />
                <Tab label="校正模型" />
            </Tabs>

            {tabIdx === 0 && (
                <PointPairList focus={focus} map={map} dispatch={dispatch} />
            )}
        </Styled>
    );
}

const Styled = styled.div`
    min-height: 8em;

    .pane-header {
        display: flex;
        align-items: center;
        background-color: #f0f0f0;

        .home-btn {
            height: 1.2em;
            font-size: 2em;

            .MuiSvgIcon-root {
                font-size: 1em;
            }
        }

        label {
            font-size: 1.2em;
        }
    }

    .MuiTabs-root {
        min-height: unset;
        .MuiTab-root {
            min-height: unset;
        }
    }
`;
