import React from "react";
import styled from "styled-components";
import Ribbon from "./Ribbon";

import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";

import useReturnPath from "./useReturnPath";

function RibbonHeader(props) {
    const { className, defaultReturnPath, title, middle, extra } = props;
    const goback = useReturnPath(defaultReturnPath);

    return (
        <div className={`${className} page-header`}>
            <Ribbon className="page-header-title">
                {goback && (
                    <IconButton className="btn-return" onClick={goback}>
                        <ArrowBackIosRoundedIcon fontSize="large" />
                    </IconButton>
                )}
                {title && <div className="title">{title}</div>}
            </Ribbon>
            <span className="page-header-middle">{middle}</span>
            <span className="page-header-extra">{extra}</span>
        </div>
    );
}

export default styled(RibbonHeader)`
    padding-top: 10px;
    display: flex;
    align-items: baseline;

    > .page-header-title {
        display: flex;
        text-align: center;
        margin: unset;

        > .btn-return {
            margin-left: -1rem;
            color: white;
        }

        > div.title {
            flex: 1 1;
        }
    }

    > .page-header-middle {
        flex: 1 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    > .page-header-extra {
        display: flex;
        justify-content: flex-end;
        padding-right: 2rem;
        min-width: 10rem;
    }
`;
