import React from "react";
import { useState, useCallback } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import styled from "styled-components";
import clsx from "clsx";

import SearchBar from "./SearchBar";
import ResultList from "./ResultList";
import MenuPanel from "./MenuPanel";

async function search(searchText) {
    let url = `/api/search`;
    url = url + "?" + new URLSearchParams([["q", searchText]]);

    let response = await fetch(url);
    let data = await response.json();

    return data;
}

export default function SearchBox(props) {
    const { state, dispatch, onSelect, hidden } = props;
    const [results, setResults] = useState([]);

    const handleClickAway = useCallback(() => {
        console.debug("click away: open_menu false");
        if (state.open === false) return;
        dispatch({ type: "OPEN_MENU", open: false });
    }, [dispatch, state.open]);

    const handleSearch = useCallback(async (searchText) => {
        const result = await search(searchText);

        if (result &&  result.error) {
            console.error("failed in searching: ", result);
            return;
        }
        
        setResults(result);
    }, []);

    const handleSearchClose = useCallback(() => {
        setResults([]);
    }, []);

    const handleMenuOpen = useCallback(() => {
        console.debug("click open-menu");
        dispatch({ type: "OPEN_MENU", open: !state.open });
    }, [dispatch, state.open]);

    let className = "side-menu";
    if (state.open) {
        className += " open";
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <StyledSearchBox hidden={hidden} className={clsx("searchbox")}>
                <SearchBar
                    menuOpen={state.open}
                    searchOpen={results.length > 0}
                    onMenuOpen={handleMenuOpen}
                    onSearchClose={handleSearchClose}
                    onSearch={handleSearch}
                />
                <ResultList onSelect={onSelect} results={results} />

                <StyledSidebar className={className}>
                    <MenuPanel state={state} dispatch={dispatch} />
                </StyledSidebar>
            </StyledSearchBox>
        </ClickAwayListener>
    );
}

const StyledSearchBox = styled.div`
    position: absolute;
    right: 0;
    margin: 5px;

    width: 20em;

    .searchbar {
        width: 100%;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;

        border-radius: 8px 8px 8px 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

        opacity: 0.6;

        &:focus-within,
        &:hover,
        &.open {
            opacity: 1;
        }

        &.open {
            border-radius: 8px 8px 0 0;
        }
    }

    .search-results {
        width: 100%;
    }
`;

const StyledSidebar = styled.div`
    --side-menu-width: 30em;
    position: absolute;

    width: var(--side-menu-width);
    height: 25em;

    top: 41px;

    opacity: 0;
    right: calc(-2em - var(--side-menu-width));
    &.open {
        opacity: 1;
        right: 0px;
        z-index: 1;
    }

    background-color: hsla(0, 0%, 100%, 1);
    border-bottom-left-radius: 4px;
    border: 1px solid #d9d9d9;
    /* box-shadow: 0 2px 4px rgba(0,0,0,0.2); */

    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);

    overflow: hidden;
    user-select: none;
    transition: all 0.2s ease-in-out;
`;
