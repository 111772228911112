import React, { useState, useImperativeHandle } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { postJsonData, deleteResource } from "../../util/json";
import DialogContent from "@material-ui/core/DialogContent";
// import clsx from "clsx";

import AsyncButton from "../../util/panel/AsyncButton";

export default React.forwardRef((props, ref) => {
    const [item, setItem] = useState(null);

    useImperativeHandle(
        ref,
        () => ({
            addShape: ({ firmSn, shapeSn }, onDone) => {
                console.log("add firmSn: ", firmSn, shapeSn);
                setItem({
                    action: "add",
                    firmSn,
                    shapeSn,
                    message: "",
                    onDone,
                });
            },
            removeShape: ({ firmSn, shapeSn }, onDone) => {
                console.log("remove lot-shape: ", firmSn, shapeSn);
                setItem({
                    action: "remove",
                    firmSn,
                    shapeSn,
                    message: "",
                    onDone,
                });
            },
        }),
        []
    );

    const handleCommit = async (e) => {
        const { firmSn, shapeSn, message } = item;

        if (item.action === "add") {
            await postJsonData(`/api/firm/${firmSn}/lot_items`, {
                firm_sn: firmSn,
                shape_sn: shapeSn,
                message: message,
            });
        } else if (item.action === "remove") {
            await deleteResource(`/api/firm/${firmSn}/lot_item/${shapeSn}`, {
                firm_sn: firmSn,
                shape_sn: shapeSn,
                message: message,
            });
        }

        if (item.onDone) {
            item.onDone();
        }

        setTimeout(() => {
            setItem(null);
        }, 100);
    };

    const handleClose = () => {
        setItem(null);
    };

    if (item === null) {
        return null;
    }

    let messageText;
    if (item.action === "add") {
        messageText = "添加地块";
    } else if (item.action === "remove") {
        messageText = "删除地块";
    }

    return (
        <Dialog
            open={item !== null}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <StyledDiv>
                <p className="content">{messageText}</p>
                <TextField
                    autoFocus
                    margin="dense"
                    id="rev_notes"
                    label="变更备注"
                    fullWidth
                    onChange={(e) =>
                        setItem({ ...item, message: e.target.value })
                    }
                />
            </StyledDiv>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    取消
                </Button>
                <AsyncButton onAction={handleCommit} color="primary">
                    确定
                </AsyncButton>
            </DialogActions>
        </Dialog>
    );
});

const StyledDiv = styled(DialogContent)`
    padding: 20px 40px;
    min-width: 30em;

    .content {
    }
`;
