import React from "react";
import styled from "styled-components";
import { useGetJson } from "../../util/async";
import ActionCurtain from "../../util/ActionCurtain";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
// import EstateInfo from "./EstateInfo";
// import FirmInfo from "./FirmInfo";
// import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import HomeIcon from "@material-ui/icons/Home";
// import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Tooltip from "@material-ui/core/Tooltip";

// import AnimatedPane from "../../map/AnimatedPane";
import { Link } from "react-router-dom";

export default function MapList(props) {
    // const { map, dispatch } = props;
    let { result: mapList, ...loader } = useGetJson(
        `/api/calibrator/map/list`,
        []
    );

    if (!mapList) {
        return (
            <Styled>
                <ActionCurtain action={loader} />
            </Styled>
        );
    }

    return (
        <Styled>
            <div className="pane-header">
                <Link className="home-btn" to="/map">
                    <Tooltip title="返回主图">
                        <HomeIcon />
                    </Tooltip>
                </Link>
                <label>向量图校正</label>
            </div>
            <MenuList className="map-list">
                {mapList &&
                    mapList.map((map, idx) => (
                        <MenuItem key={idx}>
                            <Link to={`/calibrator/map/${map.map_name}`}>
                                {map.map_name}
                            </Link>
                        </MenuItem>
                    ))}
            </MenuList>
        </Styled>
    );
}

const Styled = styled.div`
    position: absolute;
    right: 0;
    width: 23em;

    background-color: #fff;
    box-shadow: 1px 1px 4px hsla(0, 0%, 40%, 0.5);

    min-height: 8em;

    .pane-header {
        display: flex;
        align-items: center;
        background-color: #f0f0f0;

        .home-btn {
            height: 1.2em;
            font-size: 2em;
            width: 1.3em;
            margin-left: 0.2em;

            .MuiSvgIcon-root {
                font-size: 1em;
                color: #888;
            }
        }

        label {
            font-size: 1.2em;
        }
    }

    .map-list {
        list-style-type: none;
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
    }
`;
