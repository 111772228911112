import { useEffect } from "react";

export default function useMapClick(mapObj, callback, dependencies) {
    useEffect(
        () => {
            if (!mapObj) return;

            const clickHandler = event => {
                event.preventDefault();

                const pixel = mapObj.getEventPixel(event);
                const coordinate = mapObj.getCoordinateFromPixel(pixel);
                const stack = [];
                mapObj.forEachFeatureAtPixel(pixel, (feature, layer) => {
                    stack.push({ feature, layer });
                });
                return callback({ pixel, coordinate, stack });
            };

            const viewport = mapObj.getViewport();
            viewport.addEventListener("click", clickHandler);
            return () => {
                console.log("map clickHandler removed");
                viewport.removeEventListener("click", clickHandler);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mapObj, ...dependencies]
    );
}
