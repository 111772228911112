import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { toLonLat } from "../projection";

export default function({ mapObj }) {
    const [coord, setCoord] = useState(null);

    useEffect(() => {
        if (!mapObj) return;

        const viewport = mapObj.getViewport();

        const pointmoveHandler = event => {
            // let pixel = mapObj.getEventPixel(event);
            // let coord = mapObj.getCoordinateFromPixelInternal(pixel);

            let coord = mapObj.getEventCoordinate(event);
            setCoord(coord);
        };

        const pointeroutHandler = event => {
            setCoord(null);
        };

        viewport.addEventListener("pointermove", pointmoveHandler);
        viewport.addEventListener("pointerout", pointeroutHandler);
        return () => {
            viewport.removeEventListener("pointermove", pointmoveHandler);
            viewport.removeEventListener("pointerout", pointeroutHandler);
        };
    }, [mapObj]);

    if (mapObj === null || coord === null) return null;

    const lonlat = toLonLat(coord);

    return (
        <Styled className="status-bar">
            <div className="position-lnglat">
                {lonlat[0].toFixed(6)}, {lonlat[1].toFixed(6)}
            </div>
            |
            <div className="position-xy">
                {coord[0].toFixed(2)}, {coord[1].toFixed(2)}
            </div>
        </Styled>
    );
}

const Styled = styled.div`
    display: flex;
    position: absolute;
    pointer-events: none;

    bottom: 0.5em;
    right: 3.5em;
    background-color: hsla(0, 0%, 100%, 0.7215686274509804);
    padding: 2px;
    font-size: 0.8em;
    letter-spacing: 0.01em;
`;
