import React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import PlaceIcon from "@material-ui/icons/Place";
import Tooltip from "@material-ui/core/Tooltip";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import ReplayIcon from "@material-ui/icons/Replay";
import ActionButton from "../../util/ActionButton";
import axios from "axios";

export default function PointPairList(props) {
    const { focus, map, dispatch } = props;
    const { originalPoints, points } = map;

    const pristine = originalPoints === points;

    const handleSave = useCallback(async () => {
        await axios.put(`/api/calibrator/model/${map.name}`, {
            points
        });
        dispatch({ type: "SAVE", points: map.points });
        dispatch({ type: "FOCUS" });
    }, [map, points, dispatch]);

    return (
        <Styled>
            <ol className="point-list">
                <li className="control-point header">
                    <div className="point-no">
                        <AddControlPointButton dispatch={dispatch} />
                    </div>
                    <div className="source">源点</div>
                    <div className="target">目标点</div>
                    <div className="target">偏差</div>
                    <div className="ctrlbar"></div>
                </li>
                {points &&
                    points.map((point, idx) => (
                        <ControlPointItem
                            key={idx}
                            {...{ idx, focus, point, dispatch }}
                        />
                    ))}
            </ol>
            <div className="form-ctrlbar">
                <Button
                    size="small"
                    disabled={pristine}
                    startIcon={<ReplayIcon />}
                    onClick={() => { 
                        dispatch({ type: "RESET" });
                        dispatch({ type: "FOCUS" });
                    }}
                >
                    取消
                </Button>
                <ActionButton
                    size="small"
                    disabled={pristine}
                    startIcon={<CloudQueueIcon />}
                    // onClick={}

                    // color="primary"
                    onAction={handleSave}
                >
                    保存
                </ActionButton>
            </div>
        </Styled>
    );
}

function ControlPointItem({ idx, focus, point, dispatch }) {
    let sourceFocused =
        focus &&
        focus.index === idx &&
        focus.type === "source" &&
        focus.focused;

    let targetFocused =
        focus &&
        focus.index === idx &&
        focus.type === "target" &&
        focus.focused;

    let deviation = null;
    if (point.source && point.target) {
        const { source, target } = point;
        const dx = target[0] - source[0];
        const dy = target[1] - source[1];
        deviation = Math.sqrt(dx * dx + dy * dy);
    }

    return (
        <li className="control-point" key={idx}>
            <div className="point-no">{idx + 1}</div>
            <div className={clsx("source", sourceFocused && "focused")}>
                <PickPoint
                    type="source"
                    focused={sourceFocused}
                    index={idx}
                    point={point}
                    dispatch={dispatch}
                />
                <LocatePoint
                    type="source"
                    focused={sourceFocused}
                    index={idx}
                    point={point}
                    dispatch={dispatch}
                />
            </div>
            <div className={clsx("target", targetFocused && "focused")}>
                <PickPoint
                    type="target"
                    focused={targetFocused}
                    index={idx}
                    point={point}
                    dispatch={dispatch}
                />

                <LocatePoint
                    type="target"
                    focused={targetFocused}
                    index={idx}
                    point={point}
                    dispatch={dispatch}
                />
            </div>
            <div className="deviation">
                {deviation !== null ? (
                    <>
                        <span>{deviation.toFixed(1)}</span>
                        <span className="unit">m</span>
                    </>
                ) : (
                    ""
                )}
            </div>
            <div className="ctrl-btn">
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => dispatch({ type: "REMOVE_POINT", point })}
                >
                    <CloseIcon />
                </IconButton>
            </div>
        </li>
    );
}

function PickPoint(props) {
    const { type, focused, index, point, dispatch } = props;

    const empty = !point || !Array.isArray(point[type]);
    return (
        <Tooltip title="拾取">
            <IconButton
                className={clsx("pick-btn", empty && "empty")}
                size="small"
                onClick={() => {
                    dispatch({
                        type: "FOCUS",
                        focusType: type,
                        index: index,
                        focused: !focused
                    });
                }}
            >
                <MyLocationIcon />
            </IconButton>
        </Tooltip>
    );
}

function LocatePoint(props) {
    const { type, focused, index, point, dispatch } = props;

    return (
        <Tooltip title="定位">
            <span>
                <IconButton
                    className="location-btn"
                    size="small"
                    disabled={!Array.isArray(point[type])}
                    onClick={() => {
                        dispatch({
                            type: "LOCATE_POINT",
                            focusType: type,
                            index: index,
                            focused: !focused
                        });
                    }}
                >
                    <PlaceIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
}

function AddControlPointButton({ dispatch }) {
    return (
        <Tooltip title="添加控制点" arrow>
            <IconButton
                className="add-btn"
                color="primary"
                size="small"
                onClick={() => dispatch({ type: "ADD_EMPTY_POINT" })}
            >
                <AddIcon />
            </IconButton>
        </Tooltip>
    );
}

const Styled = styled.div`
    display: flex;
    flex-direction: column;

    ol {
        padding: 10px 20px;

        .control-point {
            display: grid;
            grid-template-columns: 2em 1fr 1fr 1fr 1.5em;

            &.header {
                background-color: #fafafa;
                .point-no,
                .source,
                .target {
                    font-size: 0.8em;
                }

                .point-no .MuiSvgIcon-root {
                    font-size: 1em;
                }
            }

            .point-no {
                user-select: none;
                align-self: center;
                color: #8e8e8e;
                text-align: center;
            }

            > .source,
            > .target {
                text-align: center;
                align-self: center;

                .pick-btn {
                    /* margin-right: 0.5em; */
                    color: hsla(120, 100%, 25%, 1);

                    &.empty {
                        color: hsla(0, 0%, 60%, 1);
                    }
                }

                &.focused {
                    .pick-btn {
                        color: hsla(39, 100%, 50%, 1);
                    }
                }

                .location-btn {
                    /* margin-right: 0.5em; */
                    color: hsla(120, 100%, 25%, 1);
                    &[disabled] {
                        color: hsla(0, 0%, 60%, 1);
                    }
                }
            }

            .deviation {
                justify-self: end;
                font-size: 0.8em;
                align-self: center;
                padding-right: 0.5em;
                color: #777;

                .unit {
                    font-size: 0.9em;
                }
            }

            .add-btn {
                color: red;
            }

            .ctrl-btn {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding: 5px 20px 5px 20px;

                .MuiSvgIcon-root {
                    color: #8e8e8e;
                    font-size: 0.8em;
                }
            }
        }
    }

    .form-ctrlbar {
        display: flex;
        justify-content: flex-end;

        padding: 4px 2em 5px 2em;
    }
`;
