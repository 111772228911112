import React, { useRef } from "react";
// import styled from "styled-components";
// import clsx from "clsx";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from "@material-ui/icons/History";

import FirmInfoEditDialog from "./FirmInfoEditDialog";
import FirmInfoHistoryDialog from "./FirmInfoHistoryDialog";

export default function FirmInfo({ firm, mutateFirm }) {
    const dialogRef = useRef(null);
    const historyDialogRef = useRef(null);

    if (!firm) {
        return <div className="firm-info"></div>;
    }

    return (
        <div className="firm-info">
            <div className="usci-field">
                <label>社会统一信用代码</label>
                <div className="code">
                    <span className="usci">{firm.usci}</span>
                    {firm.usci_seqno && (
                        <span className="seqno">{firm.usci_seqno}</span>
                    )}
                </div>
            </div>
            <div className="ctrl-btns">
                <IconButton
                    size="small"
                    onClick={() => {
                        historyDialogRef.current.showHistory(firm.firm_sn);
                    }}
                >
                    <HistoryIcon />
                </IconButton>
                <Button onClick={() => dialogRef.current.edit(firm)}>
                    编辑企业基本信息
                </Button>
            </div>
            <FirmInfoEditDialog
                ref={dialogRef}
                onDone={() => {
                    mutateFirm();
                }}
            />
            <FirmInfoHistoryDialog ref={historyDialogRef} />
        </div>
    );
}
