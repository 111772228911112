import useSWR from "swr";

export async function fetchGetJson(url) {
    let response = await fetch(url);
    return await checkJsonResponseErrors(response);
}

export function useGetJson(url) {
    return useSWR(url, fetchGetJson);
}

export async function postJsonData(url, data) {
    const bodyData = data ? JSON.stringify(data) : null;
    const response = await fetch(url, {
        method: "POST",
        body: bodyData,
    });

    return await checkJsonResponseErrors(response);
}

export async function putJsonData(url, data) {
    const bodyData = data ? JSON.stringify(data) : null;
    const response = await fetch(url, {
        method: "PUT",
        body: bodyData,
    });

    return await checkJsonResponseErrors(response);
}

export async function deleteResource(url, data) {
    const bodyData = data ? JSON.stringify(data) : null;
    const response = await fetch(url, {
        method: "DELETE",
        body: bodyData,
    });

    return await checkJsonResponseErrors(response);
}

async function checkJsonResponseErrors(response) {
    let data;
    const contentType = response.headers.get("content-type");
    if (contentType) {
        if (contentType.includes("application/json")) {
            data = await response.json();
        } else if (contentType.includes("text/")) {
            data = await response.text();
        } else {
            data = response;
        }
    } else {
        throw new Error("Missing contentType");
    }

    if (response.ok) {
        return data;
    }

    const { status } = response;

    if (status >= 400 && status <= 499) {
        if (status === 401) {
            throw new UnauthorizedError(data);
        } else if (status === 403) {
            throw new ForbiddenError(data);
        }

        throw new ClientError(data);
    }

    if (response.status >= 500 && response.status <= 599) {
        throw new ServerError(data);
    }
}

export class ClientError extends Error {
    constructor(error) {
        let errorName;
        let message;
        if (typeof error === "object") {
            errorName = error.name;
            message = error.message;
        } else if (typeof error === "string") {
            errorName = "ClientError";
            message = error;
        } else {
            errorName = "ClientError";
            message = error.toString();
        }
        super(message);
        this.name = errorName;
    }
}

export class UnauthorizedError extends ClientError {
    constructor(message) {
        super(message);
        this.name = "UnauthorizedError";
    }
}

export class ForbiddenError extends ClientError {
    constructor(message) {
        super(message);
        this.name = "ForbiddenError";
    }
}

export class ServerError extends Error {
    constructor(error) {
        let errorName, message, details;

        if (typeof error === "object") {
            errorName = error.name;
            message = error.message;
            details = error.details;
        } else if (typeof error === "string") {
            errorName = "ClientError";
            message = error;
            details = "";
        } else {
            errorName = "ClientError";
            message = error.toString();
            details = "";
        }

        super(message);
        this.name = errorName;
        this.details = details;
    }
}
