import React from 'react';
import { 
    useState, 
    useContext, 
    // useEffect, 
    useCallback 
} from 'react';


import { logout } from './model';


const UserSession = React.createContext({
});

class Session {

    constructor(profile) {
        if (profile !== undefined) {
            this._profile = profile;
            if (profile !== null) {
                this._user_name = profile.name;
                this._user_title = profile.title;
                this._user_roles = new Set(profile.roles);
            }
        } else {
            this._profile = null;
        }
    }

    get authenticated() {
        return this._profile !== null;
    }

    get name() {
        return this._user_name;
    }

    get title() {
        return this._user_title;
    }

    /**
     * 判断当前会话是否具有给定角色.
     * 
     * session.isRole('Reviewer')
     * 
     */
    get isRole() {
        if (this._user_roles === undefined) {
            return false;
        }

        for (let required_role of arguments) {
            if (this._user_roles.has(required_role)) {
                return true;
            }
        }

        if (arguments.length === 0) {
            return true;
        }

        return false;
     }

    setUserProfile() {
        throw new Error();
    }
}

export function UserSessionProvider(props) {

    const [session, setSession] = useState(() => new Session());


    session.setUserProfile = useCallback((userProfile) => {
        setSession(new Session(userProfile));
    }, [setSession]);

    session.logout = useCallback((userProfile) => {
        (async () => {
            try {
                await logout();
                document.location.href = '/login';
                // setSession(new Session(null));
            } catch(err) {
                console.error(err);
            } 
        })();
        
        
    }, []);

    // decorateSession(session, setSession);

    return (
        <UserSession.Provider value={session}>
            {props.children}
        </UserSession.Provider>
    );
}

export function useUserSession() {
    const session = useContext(UserSession);
    return session;
}