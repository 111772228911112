import React from "react";
import styled from "styled-components";

function Page({ className, children }) {
    return <div className={`${className} cardpage`}>{children}</div>;
}

export default styled(Page)`
    display: flex;
    flex-direction: column;
    /* justify-content: center; */

    margin: 1rem 30px 0px 30px;
    height: calc(100vh - 2rem);

    --header-height: 6rem;

    .page-header {
        height: var(--header-height);
    }

    .page-body {
        height: calc(100% - var(--header-height));
    }

    background-color: #fff;
    box-shadow: 3px 4px 6px 0px;
`;
