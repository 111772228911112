// import { WMTS as WMTSSource } from "ol/source";
// import WMTSTileGrid from "ol/tilegrid/WMTS";
import { XYZ as XYZSource } from "ol/source";
// import TileDebug from "ol/source/TileDebug";
// import TileGrid from "ol/tilegrid/TileGrid";
import { Tile as TileLayer } from "ol/layer";
// import { getProjection } from "./projection";
// import {
//     getTopLeft as getExtentTopLeft,
//     getWidth as getExtentWidth
// } from "ol/extent";
// import TileState from "ol/TileState";
// import { listen } from "ol/events";
// import { TileLocalDB } from "./TileLocalDB";

import LayerGroup from "ol/layer/Group";

export default class TiandituLayer extends LayerGroup {
    constructor() {
        super({
            layers: [
                new TileLayer({
                    opacity: 1,
                    source: createTileSource("img_w"),
                    useInterimTilesOnError: false,
                }),
                new TileLayer({
                    opacity: 1,
                    source: createTileSource("cia_w"),
                    useInterimTilesOnError: false,
                }),
                // new TileLayer({
                //     source: new TileDebug({
                //         projection: "EPSG:900913",
                //         wrapX: true
                //     })
                // })
            ],
        });
    }
}

export function createTiandiImageLayers() {
    return [
        new TileLayer({
            opacity: 1,
            source: createTileSource("img_w"),
            useInterimTilesOnError: false,
        }),
        new TileLayer({
            opacity: 1,
            source: createTileSource("cia_w"),
            useInterimTilesOnError: false,
        }),
        // new TileLayer({
        //     source: new TileDebug({
        //         projection: "EPSG:900913",
        //         wrapX: true
        //     })
        // })
    ];
}

function modulo(a, b) {
    const r = a % b;
    return r * b < 0 ? r + b : r;
}

function createTileUrlFunction(layer, accessKey) {
    return (tileCoord, pixelRatio, projection) => {
        const [z, x, y] = tileCoord;
        let idx = modulo((x << z) + y, 8);
        return `https://t${idx}.tianditu.gov.cn/DataServer?l=${z}&x=${x}&y=${y}&T=${layer}&tk=${accessKey}`;
    };
}

// function createTileLoadFunction(layer) {
//     return function(tile, src) {
//         // const [z, x, y] = tile.getTileCoord();
//         // const tileId = `${layer}_${z}_${x}_${y}`;

//         tile.getImage().src = src;

//         // fetch(src).then(response=>{
//         //     if (response.status === 200) {
//         //         const contentType = response.headers.get("content-type");
//         //         response.arrayBuffer().then(buffer=> {
//         //             const encoded = arrayBufferToBase64(buffer);
//         //             let dataurl = `data:${contentType};base64,${encoded}`;
//         //             tile.getImage().src = dataurl;
//         //         });
//         //     } else {
//         //         console.error(`error(${response.status}) load: ${src}`);
//         //         tile.setState(TileState.ERROR);
//         //         return;
//         //     }
//         // });

//         // const [z, x, y] = tileCoord;
//         // let idx = modulo((x << z) + y, 4);

//         // localdbs[idx].fetch(tileId, src).then(data => {
//         //     if (data) {
//         //         console.log(`${tileId}: length=${data.length}`)
//         //         tile.getImage().src = data;
//         //     }
//         // });
//     };
// }

// const localdbs = [
//     new TileLocalDB(),
//     new TileLocalDB(),
//     new TileLocalDB(),
//     new TileLocalDB()
// ];

let TIANDITU_ACCESS_KEY = "c73529f0b7de2e1c459968af4add64ac";
function createTileSource(layer) {
    return new XYZSource({
        projection: "EPSG:900913",
        wrapX: true,
        transition: 0,
        // cacheSize: 512,
        key: `${layer}`,
        tileUrlFunction: createTileUrlFunction(layer, TIANDITU_ACCESS_KEY),
        // tileLoadFunction: createTileLoadFunction(layer)
    });
}

// const TILE_SIZE = 256;

// function createTileGrid(extent) {
//     const resolutions = new Array(20);
//     resolutions[0] = getExtentWidth(extent) / TILE_SIZE;
//     for (let z = 1; z < 20; z++) {
//         resolutions[z] = resolutions[z - 1] / 2;
//     }

//     const tileGrid = new TileGrid({
//         extent: extent,
//         origin: getExtentTopLeft(extent),
//         resolutions: resolutions
//     });

//     return tileGrid;
// }

// function createWMTSTileSource(tileGrid, layer, matrixSet) {
//     let urls = [0, 1, 2, 3, 4, 5, 6, 7].map(
//         sn =>
//             `https://t${sn}.tianditu.gov.cn/${layer}_${matrixSet}/wmts?tk=${TK}`
//     );

//     return new WMTSSource({
//         projection: "EPSG:900913",
//         urls: urls,
//         layer: layer,
//         matrixSet: matrixSet,
//         format: "tiles",
//         style: "default",
//         tileGrid: tileGrid,
//         wrapX: true
//     });
// }
// function createWMTSTileGrid(projection) {
//     const projExtent = projection.getExtent();
//     const resolutions = new Array(18);
//     const matrixIds = new Array(18);

//     resolutions[0] = getExtentWidth(projExtent) / 256 / 2;
//     matrixIds[0] = 1;
//     for (let z = 1; z < 18; ++z) {
//         resolutions[z] = resolutions[z - 1] / 2;
//         matrixIds[z] = z + 1;
//     }

//     const tileGrid = new WMTSTileGrid({
//         extent: projExtent,
//         origin: getExtentTopLeft(projExtent),
//         resolutions: resolutions,
//         matrixIds: matrixIds
//     });

//     return tileGrid;
// }

// function arrayBufferToBase64(buffer) {
//     const bytes = new Uint8Array(buffer);
//     let binary = "";
//     var len = bytes.byteLength;
//     for (let i = 0; i < len; i++) {
//         binary += String.fromCharCode(bytes[i]);
//     }
//     return window.btoa(binary);
// }
