import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowBackIosTwoToneIcon from "@material-ui/icons/ArrowBackIosTwoTone";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import { useLocation, useHistory } from "react-router-dom";

const STATUS_UNLOADED = 0;
const STATUS_OK = 1;
const STATUS_LOADING = 2;
const STATUS_ERROR = 3;

function redirectPage(pagination, history, location, numOfPage) {
    const pageFieldName = `${pagination._searchPrefix}page`;
    let params = new URLSearchParams(location.search);
    let pageNo = params.get(pageFieldName);
    pageNo = parseInt(pageNo !== null ? pageNo : 0);

    const { maxPageNo } = pagination;

    let nextPageNo = pageNo + numOfPage;
    if (nextPageNo < 0) {
        nextPageNo = 0;
    }

    if (pagination._status !== STATUS_UNLOADED) {
        if (nextPageNo > maxPageNo) {
            nextPageNo = maxPageNo;
        }

        if (pageNo === nextPageNo) {
            return;
        }
    }

    params.set(pageFieldName, `${nextPageNo}`);
    const url = `${location.pathname}?${params}`;
    history.push(url);
}

export function Pagination({ pagination }) {
    const location = useLocation();
    const history = useHistory();

    const searchPageNoName = `${pagination._searchPrefix}page`;
    const params = new URLSearchParams(location.search);
    let pageNo = params.get(searchPageNoName);
    pageNo = parseInt(pageNo !== null ? pageNo : 0);

    useEffect(() => {
        let _pageNo = parseInt(pageNo);
        if (!isNaN(_pageNo)) {
            gotoPage(pagination, _pageNo);
        }
    }, [pagination, pageNo]);

    if (!pagination) {
        return null;
    }

    // console.log(
    //     `Pagination prefix: '${pagination._searchPrefix}' `,
    //     ` ${pagination.pageNo}/${pagination.maxPageNo}, `,
    //     `records: ${pagination.records?.length}, `,
    //     `data: ${pagination._data.length}`
    // );

    return (
        <ButtonGroup variant="contained" className="pagination">
            <Button
                className="pagination-back"
                disabled={pagination.isFirstPage}
                onClick={() => {
                    redirectPage(pagination, history, location, -1);
                }}
            >
                <ArrowBackIosTwoToneIcon />
            </Button>
            <Button
                className="pagination-prev"
                disabled={pagination.isLastPage}
                onClick={() => {
                    redirectPage(pagination, history, location, 1);
                }}
            >
                <ArrowForwardIosRoundedIcon />
            </Button>
        </ButtonGroup>
    );
}

/////////////////////////////////////////////////////////////////////////////////////////////////
export function useLocationPageNo() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    let pageNo = params.get("page");
    return parseInt(pageNo !== null ? pageNo : 0);
}

export function usePagination(options) {
    const [state, setState] = useState(() => {
        const { limitOfPage, fetcher, searchPrefix } = options;
        const pagination = new PageContext(0, limitOfPage);
        pagination._fetcher = fetcher;
        pagination._searchPrefix =
            searchPrefix !== undefined ? searchPrefix : "";

        return pagination;
    });

    if (state._setState === null) {
        state._setState = setState;
    }

    return state;
}

class PageContext {
    constructor(pageNo, limitOfPage) {
        this.pageNo = pageNo;
        this.limitOfPage = limitOfPage;
        this._searchPrefix = "";
        this._status = STATUS_UNLOADED;
        this._error = null;
        this._data = [];
        this._fetcher = null;
        this._setState = null;
    }

    clone() {
        const obj = new PageContext(this.pageNo, this.limitOfPage);
        obj._searchPrefix = this._searchPrefix;
        obj._status = this._status;
        obj._status = this._status;
        obj._error = this._error;
        obj._data = this._data;
        obj._fetcher = this._fetcher;
        obj._setState = this._setState;

        return obj;
    }

    get searchPrefix() {
        return this._searchPrefix;
    }

    get loading() {
        return this.status === STATUS_LOADING;
    }

    get error() {
        return this._error;
    }

    get isFirstPage() {
        return this.pageNo === 0;
    }

    get isLastPage() {
        return this.pageNo === this.maxPageNo;
    }

    get maxPageNo() {
        return Math.trunc(this._data.length / this.limitOfPage);
    }

    get records() {
        const start = this.pageNo * this.limitOfPage;
        return this._data.slice(start, start + this.limitOfPage);
    }

    setFetcher(fetcher) {
        this._fetcher = fetcher;
    }

    reload() {
        const newState = this.clone();
        newState._status = STATUS_UNLOADED;
        newState._data = [];
        newState.pageNo = 0;
        gotoPage(newState, newState.pageNo);
    }

    clear() {
        this._status = STATUS_UNLOADED;
        this._data = [];
        this.pageNo = 0;
    }
}

function gotoPage(state, gotoPageNo, forced) {
    if (
        !forced &&
        gotoPageNo === state.pageNo &&
        state._status !== STATUS_UNLOADED
    ) {
        return;
    }

    if (state._status === STATUS_LOADING) {
        return;
    }

    if (!state._setState) {
        console.error(`pagination: UNSET 'setState'`);
        return;
    }

    if (gotoPageNo < 0) {
        gotoPageNo = 0;
    }

    if (gotoPageNo * state.limitOfPage < state._data.length) {
        const _state = state.clone();
        _state.pageNo = gotoPageNo;

        state._setState(_state);
        return;
    }

    if (!state._fetcher) {
        console.info(`pagination: UNSET fetcher`);
        return;
    }

    // 下一页没有满页的数据，尝试加载更多数据
    const newState = state.clone();
    newState._status = STATUS_LOADING;
    newState._setState(newState);

    loadMoreItems(state, gotoPageNo)
        .then((data) => {
            const newState = state.clone();
            newState._status = STATUS_OK;
            newState._data = data;

            if (gotoPageNo < newState.maxPageNo) {
                newState.pageNo = gotoPageNo;
            } else {
                // 超过数据的页码
                newState.pageNo = newState.maxPageNo;
            }

            newState._setState(newState);
        })
        .catch((error) => {
            const newState = state.clone();
            newState._status = STATUS_ERROR;
            newState._error = error;
            newState._setState(newState);
        });
}

function loadMoreItems(state, gotoPageNo) {
    let startOffset = (state.pageNo + 1) * state.limitOfPage;
    if (state._data.length < startOffset) {
        startOffset = state._data.length;
    }

    let stopOffset = (gotoPageNo + 2) * state.limitOfPage; // 多加载一页数据

    // console.log(
    //     `load page: page #${state.pageNo} / ${state.limitOfPage}, goto #${gotoPageNo}, `,
    //     `items: [${startOffset}, ${stopOffset}) (${stopOffset - startOffset})`
    // );

    return state
        ._fetcher(startOffset, stopOffset - startOffset)
        .then((items) => {
            return items.length > 0 ? state._data.concat(items) : state._data;
        });
}
