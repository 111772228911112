import React from "react";
import { useState, useRef } from "react";

import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import { Page, RibbonHeader, PageBody } from "../../util/cardpage";
import SearchTextInput from "../../util/cardpage/SearchTextInput";

import { Pagination } from "../../util/cardpage/Pagination";
import { usePagination } from "../../util/cardpage/Pagination";

import { fetchGetJson } from "../../util/json";

import FirmInfoEditDialog from "./FirmInfoEditDialog";
import FirmListInfoGrid from "./FirmListInfoGrid";

async function fecthFirmInfoList(offset, limit) {
    const params = new URLSearchParams([
        ["offset", offset],
        ["limit", limit],
    ]);

    return fetchGetJson(`/api/firm/list_profile?${params}`);
}

const LIMIT_OF_PAGE = 15;

export default function FirmListPage(props) {
    const history = useHistory();
    const firmInfoDialog = useRef(null);
    const listPagination = usePagination({
        limitOfPage: LIMIT_OF_PAGE,
        fetcher: fecthFirmInfoList,
    });

    const searchPagination = usePagination({
        searchPrefix: "q",
        limitOfPage: LIMIT_OF_PAGE,
    });

    const [searchOpen, setSearchOpen] = useState(false);
    const pagination = searchOpen ? searchPagination : listPagination;

    return (
        <StypledPage>
            <RibbonHeader
                defaultReturnPath="/"
                title="企业名单"
                middle={
                    <>
                        <Pagination pagination={pagination} />
                        <Button
                            variant="contained"
                            color="default"
                            onClick={() => {
                                firmInfoDialog.current.new();
                            }}
                        >
                            新建企业
                        </Button>
                    </>
                }
                extra={
                    <SearchTextInput
                        pagination={searchPagination}
                        onOpen={(open) => setSearchOpen(open)}
                    />
                }
            />
            <PageBody>
                <FirmListInfoGrid records={pagination.records} />
            </PageBody>

            <FirmInfoEditDialog
                ref={firmInfoDialog}
                onDone={(firm) => {
                    history.push(`/datamngt/firm/${firm.firm_sn}/profile`);
                }}
            />
        </StypledPage>
    );
}

const StypledPage = styled(Page)`
    height: calc(100vh - 3em);
    .MuiTableContainer-root {
        max-height: calc(100vh - 3em - 8em);
    }

    .result-list {
        max-height: calc(100% - 7em);
        overflow-y: scroll;
        padding-left: 4em;

        .result-item {
            margin: 5px 10px;
            font-size: 1.5em;
            width: calc(100% - 30px);

            > .title {
                padding: 5px 3px;
            }

            :hover {
                font-weight: bolder;
            }
        }
    }
`;
