import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useGetJson } from "../../util/json";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import LotEditDialog from "./LotEditDialog";
import LotHistoryDialog from "./LotHistoryDialog";
import HistoryIcon from "@material-ui/icons/History";

export default function FirmLotItemList({ firmSn, candidateShapeSn, mapRef }) {
    const dialogRef = useRef(null);
    const historyDialogRef = useRef(null);

    const [selectedShapeSn, setSelectedShapeSn] = useState(null);

    const { data: lotItems, mutate: mutateLotItems } = useGetJson(
        `/api/firm/${firmSn}/lot_items`
    );

    useEffect(() => {
        if (selectedShapeSn === null) {
            mapRef.current.selectFirm(firmSn);
        } else {
            mapRef.current.selectLotShape(selectedShapeSn);
        }
    }, [firmSn, selectedShapeSn, mapRef]);

    if (!lotItems) {
        return null;
    }

    let addable = false;
    if (candidateShapeSn) {
        addable = true;
        for (let item of lotItems) {
            if (item.shape_sn === candidateShapeSn) {
                addable = false;
                break;
            }
        }
    }

    return (
        <StyledPane>
            <div className="title">
                <label>已标记地块</label>
                <IconButton
                    size="small"
                    onClick={() => {
                        console.log("show history");
                        historyDialogRef.current.showHistory(firmSn);
                    }}
                >
                    <HistoryIcon />
                </IconButton>
            </div>
            <div className="lot-list">
                {lotItems &&
                    lotItems.map((item, index) => (
                        <div
                            key={item.shape_sn}
                            className={clsx(
                                "lot-item",
                                selectedShapeSn === item.shape_sn && "selected"
                            )}
                            onClick={() => {
                                if (selectedShapeSn === item.shape_sn) {
                                    setSelectedShapeSn(null);
                                } else {
                                    setSelectedShapeSn(item.shape_sn);
                                }
                            }}
                        >
                            <div className="measurement">
                                <span className="value">
                                    {(
                                        (item.lot_area_geom * 15) /
                                        10000
                                    ).toFixed(2)}
                                </span>
                                <span className="unit">亩</span>
                            </div>
                            <div className="ctrl-btns">
                                <IconButton
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        dialogRef.current.removeShape(
                                            {
                                                firmSn,
                                                shapeSn: item.shape_sn,
                                            },
                                            () => {
                                                setTimeout(() => {
                                                    mapRef.current.selectFirm(
                                                        firmSn
                                                    );
                                                    mutateLotItems();
                                                }, 0);
                                            }
                                        );
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    ))}
                <div className={clsx("lot-item", "btn-add", addable && "open")}>
                    <div className="plus-icon">
                        <IconButton
                            aria-label="delete"
                            onClick={() =>
                                dialogRef.current.addShape(
                                    {
                                        firmSn,
                                        shapeSn: candidateShapeSn,
                                    },
                                    () => {
                                        setTimeout(() => {
                                            mapRef.current.selectFirm();
                                            mutateLotItems();
                                        }, 0);
                                    }
                                )
                            }
                        >
                            <AddCircleOutlineIcon fontSize="large" />
                        </IconButton>
                    </div>
                </div>
            </div>
            <LotHistoryDialog ref={historyDialogRef} />
            <LotEditDialog ref={dialogRef} />
        </StyledPane>
    );
}

const StyledPane = styled.div`
    margin-top: 1.5em;

    > .title > label {
        color: #999;
        font-weight: bold;
        user-select: none;
    }

    .lot-list {
        --lot-item-width: 8em;
        display: flex;
        flex-wrap: wrap;

        .lot-item {
            position: relative;

            width: var(--lot-item-width);
            height: var(--lot-item-width);
            border-style: solid;
            border-width: 1px;
            border-color: #aaa;

            display: flex;
            justify-content: center;
            align-items: center;
            margin: 5px;

            &.btn-add {
                border-style: dashed;
                visibility: hidden;

                &.open {
                    visibility: visible;
                }
            }

            > .ctrl-btns {
                position: absolute;
                bottom: 0;
                right: 0;
            }

            &.selected {
                &::after {
                    content: " ";
                    border-style: solid;
                    border-color: hsl(0, 100%, 66%);
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    box-shadow: 0 0 2px 1px red;
                }
            }
        }

        .measurement {
            font-size: 1.2em;

            > .value {
                font-weight: bold;
            }
            > .unit {
                font-size: 0.8em;
            }
        }
    }
`;
