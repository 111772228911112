import React from "react";

import { ReactComponent as MapIcon } from "./icons/map.svg";
// import { ReactComponent as LotEditorIcon } from "./icons/loteditor.svg";
import { ReactComponent as CalibratorIcon } from "./icons/calibrator.svg";
import { ReactComponent as FolderIcon } from "./icons/folders.svg";

import IconButton from "./IconButton";

export default function ButtonList(props) {
    return (
        <>
            <IconButton to="/map" icon={<MapIcon />} title="主图" />
            <IconButton to="/datamngt" icon={<FolderIcon />} title="数据管理" />
            <IconButton
                to="/calibrator/map/list"
                icon={<CalibratorIcon />}
                title="向量校正"
            />
        </>
    );
}
