import React from "react";
// import { useState } from "react";
import { useEffect, useCallback, useRef } from "react";
import styled from "styled-components";

import OLMap from "ol/Map";
import OLMapView from "ol/View";

import { defaultSRID, fromLonLat } from "./projection";
import ZoomButton from "./control/ZoomButton";
import MapStatusBar from "./control/MapStatusBar";
import GeopointLayer from "./layer/GeopointLayer";
import EstateHighlightLayer from "./layer/EstateHighlightLayer";
import TiandituLayer from "./layer/TiandituLayer";
import ShapeTileLayer from "./layer/ShapeTileLayer";
import FirmOutlineTileLayer from "./layer/FirmOutlineTileLayer";

import HeatmapLayer from "./layer/HeatmapLayer";
// import useFeatureClickHandler from "./control/useFeatureClickHandler";
import useStateDispatcher from "./MapState";
import FeaturePane from "./pane/FeaturePane";
import SearchBox from "../menu/SearchBox";
// import useMapClick from "./control/useMapClick";
import EstateAnnotation from "./pane/EstateAnnotation";
import { handleMapClick } from "./util";

const indicators = {
    危险废物: "危险废物产生量",
    工业废水: "G102.22",
    工业废气: "G103S1.54",
};

export default function MainMap(props) {
    const mapDivRef = useRef(null);
    const firmPopoverRef = useRef(null);
    const selectionLayerRef = useRef(null);
    const mapRef = useRef(null);
    const [mainState, mainDispatch] = useStateDispatcher();

    // useFeatureClickHandler(mapRef, mainDispatch);

    const handleSelectedFirm = useCallback(
        (event) => {
            const firmSn = event.firm_sn;
            const geopointLayer = mapRef.current.get("Layer_Geopoint");
            geopointLayer.selectFirm(firmSn, (target) => {
                mainDispatch({
                    type: "SELECT_GEOPOINT",
                    ...target,
                    source: "SearchBox",
                });
            });
        },
        [mainDispatch]
    );

    useEffect(() => {
        if (mapRef.current === null || mainState.feature === null) return;
        // console.log("map feature: ", mainState.feature);
        const { estateSn, firmSn, centered, lockedFirmSn } = mainState.feature;

        const estateHighlightLayer = mapRef.current.get(
            "Layer_EstateHighlight"
        );

        console.log("effect estatesn:", estateSn, mainState);
        if (estateSn) {
            estateHighlightLayer.selectEstate(estateSn);
            if (centered) {
                estateHighlightLayer.locateOnCenter(estateSn);
            }
        }

        if (lockedFirmSn) {
            estateHighlightLayer.selectFirm(lockedFirmSn, estateSn);
            return;
        }

        if (firmSn) {
            estateHighlightLayer.selectFirm(firmSn, estateSn);
            return;
        }

        // estateHighlightLayer.clearSelected();
    }, [mainState]);

    useEffect(() => {
        if (mapRef.current === null) return;

        let { indicator } = mainState.heatmap;

        if (indicator in indicators) {
            indicator = indicators[indicator];
        }

        // console.log("heatmap: ", indicator);
        const layer = mapRef.current.get("Layer_heatmap");
        layer.loadMeasurement(indicator);
    }, [mainState]);

    useEffect(() => {
        if (!mapDivRef) return;

        // buildMap(mapDivRef, mapRef, selectionLayerRef, firmPopoverRef);

        // if (mapRef.current === null) {
        const tiandituLayer = new TiandituLayer();
        const shapeTileLayer = new ShapeTileLayer();
        const firmOutlineLayer = new FirmOutlineTileLayer();

        const estateHighlightLayer = new EstateHighlightLayer();
        const geopointLayer = new GeopointLayer();
        const heatmapLayer = new HeatmapLayer();

        const olmap = new OLMap({
            controls: [],
            layers: [
                tiandituLayer,
                shapeTileLayer,
                firmOutlineLayer,
                heatmapLayer,
                estateHighlightLayer,
                geopointLayer,
            ],
            view: new OLMapView({
                projection: defaultSRID,
                center: fromLonLat([117.52, 39.09]),
                maxZoom: 13,
                minZoom: 2,
                zoom: 8,
            }),
        });
        mapRef.current = olmap;
        selectionLayerRef.current = estateHighlightLayer;

        olmap.set("Layer_EstateHighlight", estateHighlightLayer);
        olmap.set("Layer_Geopoint", geopointLayer);
        olmap.set("Layer_heatmap", heatmapLayer);

        firmOutlineLayer.setMap(olmap);
        estateHighlightLayer.setMap(olmap);
        geopointLayer.setMap(olmap);

        olmap.on("pointermove", function (evt) {
            const handler = (event) => {
                if (!firmPopoverRef.current) {
                    console.warn("firmPopoverRef is null");
                    return;
                }

                firmPopoverRef.current.update(event);
            };

            if (evt.dragging) {
                firmOutlineLayer._hoverExit(handler);
                return;
            }

            firmOutlineLayer.handleMouseMoveEvent(evt, handler);
        });

        olmap.setTarget(mapDivRef.current);
        // }

        const clearMapClick = handleMapClick(olmap, (feature, layer) => {
            let handled;
            if (geopointLayer) {
                handled = geopointLayer.handleClickEvent(
                    feature,
                    layer,
                    (target) => {
                        if (target.selected) {
                            mainDispatch({
                                type: "SELECT_GEOPOINT",
                                ...target,
                                source: "MapClick",
                            });
                        } else {
                            mainDispatch({ type: "UNSELECT_GEOPOINT" });
                        }
                    }
                );
                if (handled) return true;
            }

            if (estateHighlightLayer) {
                handled = estateHighlightLayer.handleClickEvent(
                    feature,
                    layer,
                    (target) => {
                        const { estateSn, selected } = target;
                        mainDispatch({
                            type: "SHOW_ESTATE",
                            estateSn: selected ? estateSn : null,
                        });
                    }
                );
                if (handled) return true;
            }
        });

        return () => {
            clearMapClick && clearMapClick();
        };
    }, [mainDispatch]);

    return (
        <Styled>
            <div className="map" ref={mapDivRef} />
            <MapStatusBar mapObj={mapRef.current} />
            <ZoomButton mapObj={mapRef.current} />
            <FeaturePane
                estate={mainState.estate}
                feature={mainState.feature}
                dispatch={mainDispatch}
            />

            <SearchBox
                state={mainState}
                dispatch={mainDispatch}
                onSelect={handleSelectedFirm}
            />
            <EstateAnnotation mapRef={mapRef} controlRef={firmPopoverRef} />
        </Styled>
    );
}

const Styled = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: stretch;
    justify-content: stretch;

    background-color: #f2f2f2;

    .map-background {
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;

        width: 100%;
        height: 70%;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: -5%;
        left: -5%;
        filter: hue-rotate(300deg);
        opacity: 0.3;
    }

    .page-container {
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .page {
            pointer-events: all;
        }
    }

    .map {
        width: 100%;
        height: 100%;
    }
`;
