import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { useHistory, useLocation } from "react-router-dom";
import { fetchGetJson } from "../../util/json";

function useSearchParams(pagination) {
    const location = useLocation();
    const history = useHistory();

    const searchPrefix = pagination._searchPrefix;
    const searchPageField = `${searchPrefix}page`;

    const params = new URLSearchParams(location.search);

    let searchText = params.get(searchPrefix);
    // let pageNo = params.get(searchPageField);

    const handleSubmit = (text) => {
        if (text) {
            const params = new URLSearchParams(location.search);
            params.set(searchPrefix, text);
            params.set(searchPageField, 0);
            history.push(`${location.pathname}?${params}${location.hash}`);
        }
    };

    const handleClose = () => {
        const { pathname, search, hash } = location;
        const params = new URLSearchParams(search);
        params.delete(searchPrefix);
        params.delete(searchPageField);
        history.push(`${pathname}?${params}${hash}`);
    };

    return [searchText, handleSubmit, handleClose];
}

export default function SearchTextInput({ pagination, onOpen, className }) {
    const [
        searchText,
        handleSearchSubmit,
        handleSearchClose,
    ] = useSearchParams(pagination);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(Boolean(searchText));
    }, [searchText]);

    useEffect(() => {
        if (searchText?.length > 0) {
            pagination.setFetcher(async (offset, limit) => {
                const params = new URLSearchParams([
                    ["offset", offset],
                    ["limit", limit],
                    ["q", searchText],
                ]);
                const result = await fetchGetJson(`/api/search?${params}`);
                return result;
            });
        }
    }, [pagination, searchText]);

    const inputRef = useRef(null);

    const handleSubmit = (el) => {
        el.preventDefault();

        const text = inputRef.current?.value;
        if (text) {
            setOpen(true);
            handleSearchSubmit(text);
        } else {
            handleClose();  
        }
    };

    const handleClose = () => {
        setOpen(false);
        pagination.clear();
        handleSearchClose();
    };

    useEffect(() => {
        onOpen && onOpen(open);
    }, [onOpen, open]);

    // console.log(
    //     `SearchInput, open: ${open}, `,
    //     `search: '${searchText}', page: ${pageNo}`,
    //     `input: '${inputRef.current?.value}'`
    // );

    return (
        <StyledSearchBar className={`${className} searchbar`}>
            <form onSubmit={handleSubmit}>
                <input
                    name="searchText"
                    type="text"
                    ref={inputRef}
                    defaultValue={searchText}
                    placeholder="按公司名或关键词搜索"

                    onChange={(el) => {
                        if (el.target.value.length === 0) {
                            handleClose();
                        }
                    }}
                />
                <button type="submit" hidden={true} />
            </form>
            <div
                className="btn-clear"
                hidden={!open}
                onClick={(el) => {
                    el.preventDefault();
                    inputRef.current.value = "";
                    handleSearchSubmit("");
                    handleClose();
                }}
            >
                <ClearIcon />
            </div>
            <div className="btn-search" onClick={handleSubmit}>
                <SearchIcon />
            </div>
        </StyledSearchBar>
    );
}

const StyledSearchBar = styled.div`
    border-color: #eee;
    border-style: solid;
    border-radius: 20px;
    border-width: 1px;

    line-height: 1.5;

    transition: all 0.3s;
    display: flex;
    color: rgba(0, 0, 0, 0.65);

    align-items: center;
    padding: 5px;

    .btn-clear {
        width: 24px;
        height: 24px;
        color: #aaa;
        margin-right: 0.5em;
        display: inline-block;
        cursor: pointer;

        &[hidden] {
            visibility: collapse;
        }
    }

    .btn-search {
        height: 24px;
        position: relative;
        cursor: pointer;

        .MuiCircularProgress-root {
            position: absolute;
            position: absolute;
            right: 0;
            color: #ccc;
        }
    }

    input {
        outline: none;
        width: 100%;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5;
        border: none;
        transition: all 0.3s;
        text-overflow: ellipsis;

        ::placeholder {
            color: #ccc;
        }
    }
`;
