import React from "react";
import { useCallback } from "react";
import styled from "styled-components";

export default function({ mapObj }) {
    const duration = 300;

    const zoomInHandle = useCallback(
        event => {
            mapObj && zoom(mapObj, 1, duration);
        },
        [mapObj]
    );

    const zoomOutHandle = useCallback(
        event => {
            mapObj && zoom(mapObj, -1, duration);
        },
        [mapObj]
    );

    if (!mapObj) return null;

    return (
        <Styled className="map-control-zoom">
            <button type="button" onClick={zoomInHandle}>
                +
            </button>
            <button type="button" onClick={zoomOutHandle}>
                −
            </button>
        </Styled>
    );
}

const Styled = styled.div`
    display: flex;
    flex-direction: column;

    bottom: 0.5em;
    right: 0.5em;

    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px;

    pointer-events: auto;

    button {
        border-radius: 2px 2px 0 0;

        display: block;
        margin: 1px;
        padding: 0;
        color: white;
        font-size: 1.14em;
        font-weight: bold;
        text-decoration: none;
        text-align: center;
        height: 1.375em;
        width: 1.375em;
        line-height: 0.4em;
        background-color: rgba(0, 60, 136, 0.5);
        border: none;
    }
`;

function zoom(mapObj, delta, duration) {
    const view = mapObj.getView();
    if (!view) return;

    const currentZoom = view.getZoom();
    if (currentZoom !== undefined) {
        const newZoom = view.getConstrainedZoom(currentZoom + delta);
        if (duration > 0) {
            if (view.getAnimating()) {
                view.cancelAnimations();
            }
            view.animate({
                zoom: newZoom,
                duration: duration,
                easing: easeOut
            });
        } else {
            view.setZoom(newZoom);
        }
    }
}

function easeOut(t) {
    return 1 - easeIn(1 - t);
}

function easeIn(t) {
    return Math.pow(t, 3);
}
