import { useReducer } from "react";

const actions = {
    SELECT_MAP: (state, action) => {
        const { map_name } = action;

        const _points = [];
        return {
            ...state,
            focus: null,
            map: {
                name: map_name,
                points: _points,
                originalPoints: _points
            }
        };
    },
    ADD_EMPTY_POINT: (state, action) => {
        const _points = state.map.points;
        return {
            ...state,
            map: {
                ...state.map,
                points: [..._points, { source: null, target: null }]
            }
        };
    },
    REMOVE_POINT: (state, action) => {
        console.log("del: ", action.point);
        let points = state.map.points;
        const idx = points.indexOf(action.point);
        if (idx === -1) {
            return;
        }

        points = points.slice(0);
        points.splice(idx, 1);
        return {
            ...state,
            map: {
                ...state.map,
                points
            }
        };
    },
    FOCUS: (state, action) => {
        let { focusType, index, focused } = action;

        if (!focusType) {
            return { ...state, focus: null };
        }
        return {
            ...state,
            focus: {
                type: focusType,
                index,
                focused
            }
        };
    },
    PICK_COORDINATE: (state, action) => {
        let { shape_sn, coordinate } = action.event;
        let { type, index, focused } = state.focus;
        if (!type || index === null || !focused) return state;

        let _points = state.map.points;
        let _point = _points[index];
        let point;
        if (type === "source") {
            point = { shape_sn, source: coordinate, target: _point.target };
        } else if (type === "target") {
            point = { target: coordinate, source: _point.source };
        }

        return {
            ...state,
            map: {
                ...state.map,
                points: [
                    ..._points.slice(0, index),
                    point,
                    ..._points.slice(index + 1)
                ]
            }
        };
    },
    RESET: (state, action) => {
        return {
            ...state,
            map: {
                ...state.map,
                points: state.map.originalPoints
            }
        };
    },
    SAVE: (state, action) => {
        let { points } = action;

        let originalPoints;
        if (!points) {
            points = state.map.points;
            originalPoints = points;
        } else {
            originalPoints = points;
        }

        return {
            ...state,
            map: {
                ...state.map,
                points,
                originalPoints
            }
        };
    }
};

const _points = [];
const initialState = {
    open: false,
    focus: null,
    map: {
        name: null,
        points: _points,
        originalPoints: _points
    }
};

const reducer = (editorState, action) => {
    console.debug("action: ", action, editorState);

    const actionHandler = actions[action.type];
    if (actionHandler) {
        return actionHandler(editorState, action);
    }

    console.warn(`unkown action ${action.type}`, action);
    return editorState;
};

export default function useLotEditorDispatcher() {
    return useReducer(reducer, initialState);
}
