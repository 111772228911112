import React from "react";

import styled, { keyframes } from "styled-components";

import { CSSTransition, SwitchTransition } from "react-transition-group";

import { zoomOut } from "react-animations";
import { slideInLeft } from "react-animations";

// let _prevId = null;
export default function AnimatedPane({ id, timeout, classNames, children }) {
    // const show = id !== null && _prevId !== id;

    if (!timeout) timeout = 300;

    return (
        <Styled className="animated-pane" timeout={timeout}>
            <SwitchTransition mode="out-in">
                <CSSTransition
                    // in={show}
                    key={`${id}`}
                    timeout={timeout}
                    classNames={classNames}
                    // mountOnEnter
                    // unmountOnExit
                    // appear
                    addEndListener={(node, done) => {
                        // use the css transitionend event to mark the finish of a transition
                        node.addEventListener("transitionend", done, false);
                    }}
                >
                    {
                        ((state) => {
                            // console.log("animated-pane: child", state, id);
                            // return children;
                            return id ? children : null;
                        })}
                </CSSTransition>
            </SwitchTransition>
        </Styled>
    );
}

// exit-active 退出动画，时长要长于timeout

const enterAnimation = keyframes`${slideInLeft}`;
const exitAnimation = keyframes`${zoomOut}`;

const Styled = styled.div`
    .display-enter-active,
    .display-appear-active {
        animation: ${(props) => props.timeout}ms ${enterAnimation};
    }

    .display-exit {
        opacity: 1;
    }

    .display-exit-active {
        animation: ${(props) => props.timeout}ms ${exitAnimation};
    }

    .display-exit-done {
    }
`;
