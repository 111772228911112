import React from "react";
import styled from "styled-components";

function Ribbon(props) {
    return <h4 className={props.className}>{props.children}</h4>;
}

// const fontSize = 32;

// https://freefrontend.com/css-ribbons/
// https://uicookies.com/css-ribbons/
export default styled(Ribbon)`
    width: max-content;
    min-width: 12em;
    position: relative;
    padding: 0 0.5em;
    font-size: 2em;
    /* margin: 0 0 0 -0.625em; */
    line-height: 1.875em;
    color: #f6f2c8;
    border-radius: 0 0.156em 0.156em 0;
    background: rgb(123, 159, 199);
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.5);

    box-sizing: content-box;

    &:before,
    &:after {
        position: absolute;
        content: "";
        display: block;
        box-sizing: content-box;
    }

    &:before {
        width: 0.469em;
        height: 100%;
        padding: 0 0 0.438em;
        top: 0;
        left: -0.469em;
        background: inherit;
        border-radius: 0.313em 0 0 0.313em;
    }

    &:after {
        width: 0.313em;
        height: 0.313em;
        background: rgba(0, 0, 0, 0.35);
        bottom: -0.313em;
        left: -0.313em;
        border-radius: 0.313em 0 0 0.313em;
        box-shadow: inset -1px 2px 2px rgba(0, 0, 0, 0.3);
    }

    @media (max-width: 600px) {
        & {
            line-height: 1.143em;
            padding: 0.5em;
        }

        &:before,
        &:after {
            font-size: 0.714em;
        }
    }
`;
