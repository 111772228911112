export function timeago(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    interval = seconds / 86400;
    if (interval > 2) {
        const d = [
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
        ].join("-");
        const t = [
            date.getHours().toString().padStart(2, "0"),
            date.getMinutes().toString().padStart(2, "0"),
            date.getSeconds().toString().padStart(2, "0"),
        ].join(":");
        return d + " " + t;
    }

    if (interval > 1) {
        return Math.floor(interval) + " 天前";
    }

    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " 小时前";
    }

    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " 分钟前";
    }
    return Math.floor(seconds) + " 秒前";
}

export function dateago(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    interval = seconds / 86400;
    if (interval > 2) {
        const d = [
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
        ].join("-");

        return d;
    }

    if (interval > 1) {
        return Math.floor(interval) + " 天前";
    }

    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " 小时前";
    }

    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " 分钟前";
    }
    return Math.floor(seconds) + " 秒前";
}
