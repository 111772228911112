import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import GeoJSON from "ol/format/GeoJSON";
import { Style, Fill, Stroke } from "ol/style";
import Pbf from "pbf";
import geobuf from "geobuf";
import LRUCache from "ol/structs/LRUCache";
import { getCenter } from "ol/extent";
// import Feature from "ol/Feature";
import RenderFeature from "ol/render/Feature";

export default class EstateSelectionLayer extends VectorLayer {
    constructor() {
        super({
            source: new VectorSource(),
            style: function (feature) {
                // const firmSn = feature.get("firm_sn");
                // const estateSn = feature.get("estate_sn");
                const selected = feature.get("selected");
                const focused = feature.get("focused");

                if (!selected) return null;

                if (focused) return featureFocusedStyle;

                return featureSelectedStyle;
            },
        });

        this._feature_cache = new LRUCache(200);

        this.selectedFirm = {};
        this._selectedEstate = {};
    }

    setMap(map) {
        this._map = map;
    }

    isSelectedEstate(estateSn) {
        return estateSn in this._selectedEstate;
    }

    // select({ estateSn, selected }, callback) {
    //     console.assert(estateSn !== undefined && estateSn !== null);
    //     // console.assert(selected === true || selected === false);

    //     if (selected) {
    //         this._set_selected(estateSn, callback);
    //     } else {
    //         this._unset_selected(estateSn, callback);
    //     }
    // }

    locateOnCenter(estateSn) {
        console.log("locate center: ", estateSn);

        for (let feature of this.getSource().getFeatures()) {
            if (feature.get("estate_sn") === estateSn) {
                const center = getCenter(feature.getGeometry().getExtent());
                console.log("center:", center);
                this._map && this._map.getView().setCenter(center);

                break;
            }
        }
    }

    handleClickEvent(feature, layer, callback) {
        // const pixel = this._map.getEventPixel(event);
        // this._map.forEachFeatureAtPixel(pixel, (feature, layer) => {
        console.log("click: ", feature);
        if (feature.constructor !== RenderFeature && layer === this) {
            return false;
        }

        // 从TileLayer选择地块，单独下载地块通过HighlightLayer显示
        const estateSn = feature.get("estate_sn");
        if (!this.isSelectedEstate(estateSn)) {
            this.selectEstate(estateSn, (target) => {
                if (callback) {
                    callback(target);
                }
            });
        } else {
            this.unselectEstate(estateSn, (target) => {
                if (callback) {
                    callback(target);
                }
            });
        }

        return true; // 事件处理到此结束
        // });
    }

    selectEstate(estateSn, callback) {
        // console.log("select: ", this._selectedEstate);
        if (estateSn in this._selectedEstate) {
            return;
        }

        if (!estateSn) {
            console.warn("invalid estate_sn: ", estateSn);
            return;
        }

        fetchFeature(estateSn).then((features) => {
            if (!features) return;

            // let estateFirmSn = null;
            for (let feature of features) {
                const _estateSn = feature.get("estate_sn");
                feature.set("selected", true);
                if (_estateSn === estateSn) {
                    // estateFirmSn = feature.get("firm_sn");
                    feature.set("focused", true);
                }
            }

            for (let estateSn in this._selectedEstate) {
                // 取消其它已选中的图形
                this.unselectEstate(estateSn);
            }

            this.getSource().addFeatures(features);
            this._selectedEstate[estateSn] = features;
            if (callback) {
                callback({ estateSn, selected: true });
            }
        });
    }

    unselectEstate(estateSn, callback) {
        // console.log("unselect: ", estateSn, this._selectedEstate);
        if (!(estateSn in this._selectedEstate)) {
            return;
        }

        console.log("unselect estateSn: ", estateSn);

        const source = this.getSource();
        for (let feature of this._selectedEstate[estateSn]) {
            console.log("unselect: ", feature);
            source.removeFeature(feature);
        }

        delete this._selectedEstate[estateSn];

        if (callback) {
            callback({ estateSn, selected: false });
        }
    }

    selectFirm(firmSn, estateSn, callback) {
        const source = this.getSource();
        this._selectedEstate = {};

        fetchFirmEstateFeature(firmSn).then((features) => {
            let estateFirmSn = null;
            for (let feature of features) {
                const _estateSn = feature.get("estate_sn");
                feature.set("selected", true);
                if (_estateSn === estateSn) {
                    estateFirmSn = feature.get("firm_sn");
                    feature.set("focused", true);
                }
            }
            // source.clear();
            source.addFeatures(features);
            this._selectedEstate[estateSn] = features;
            callback && callback({ estateSn, firmSn: estateFirmSn });
        });
    }

    clearSelected() {
        for (let feature of this.getSource().getFeatures()) {
            feature.set("selected", false);
        }

        this.getSource().clear();
    }
}

const featureSelectedStyle = new Style({
    stroke: new Stroke({
        color: "hsla(28, 100%, 53%, 1)",
        width: 1,
    }),
    fill: new Fill({
        color: "hsla(28, 100%, 53%, 0.2)",
    }),
});

const featureFocusedStyle = new Style({
    stroke: new Stroke({
        color: "hsla(339, 100%, 48%, 1)",
        width: 1,
    }),
    fill: new Fill({
        color: "hsla(339, 100%, 48%, 0.2)",
    }),
});

const geojsonFormat = new GeoJSON();

function fetchFeature(estate_sn) {
    return fetch(`/api/map/estate_${estate_sn}`)
        .then((response) => {
            if (response.ok) return response.arrayBuffer();
            console.error(`fetch ${response.status}: ${response.statusText}`);
            return null;
        })
        .then((data) => {
            if (!data) return [];

            const geojson = geobuf.decode(new Pbf(data));
            if (!geojson) {
                console.error("加载无效的GeoJSON");
                return [];
            }
            return geojsonFormat.readFeatures(geojson);
        })
        .catch((e) => {
            console.error(e);
        });
}

function fetchFirmEstateFeature(firmSn) {
    return fetch(`/api/map/estate_firm_${firmSn}`)
        .then((response) => {
            if (response.ok) return response.arrayBuffer();
            console.error(`fetch ${response.status}: ${response.statusText}`);
        })
        .then((data) => {
            const geojson = geobuf.decode(new Pbf(data));
            if (!geojson || !geojson.features) {
                console.error("加载无效的GeoJSON");
                return [];
            }
            // console.log(11111, geojson);
            return geojsonFormat.readFeatures(geojson);
        });
}
