import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Style, Fill, Stroke } from "ol/style";
import { getCenter } from "ol/extent";
import RenderFeature from "ol/render/Feature";
import { fetchGeoJsonFeatures } from "../../map/geojson";

export default class EstateSelectionLayer extends VectorLayer {
    constructor() {
        super({
            source: new VectorSource(),
            style: featureSelectedStyle,
        });

        this.selectedFirm = {};
        this._selectedEstate = {};
    }

    setMap(map) {
        this._map = map;
    }

    isSelectedEstate(estateSn) {
        return estateSn in this._selectedEstate;
    }

    locateOnCenter(shapeSn) {
        console.log("locate center: ", shapeSn);

        for (let feature of this.getSource().getFeatures()) {
            if (feature.get("estate_sn") === shapeSn) {
                const center = getCenter(feature.getGeometry().getExtent());
                console.log("center:", center);
                this._map && this._map.getView().setCenter(center);

                break;
            }
        }
    }

    handleClickEvent(feature, layer, callback) {
        // const pixel = this._map.getEventPixel(event);
        // this._map.forEachFeatureAtPixel(pixel, (feature, layer) => {
        // console.log("click: ", feature);
        if (feature.constructor !== RenderFeature && layer === this) {
            return false;
        }

        // 从TileLayer选择地块，单独下载地块通过HighlightLayer显示
        let shapeSn = feature.get("estate_sn");
        if (shapeSn === undefined) {
            shapeSn = feature.get("shape_sn");
        }

        if (!this.isSelectedEstate(shapeSn)) {
            this.selectEstate(shapeSn, (target) => {
                if (callback) {
                    callback(target);
                }
            });
        } else {
            this.unselectEstate(shapeSn, (target) => {
                if (callback) {
                    callback(target);
                }
            });
        }

        return true; // 事件处理到此结束
        // });
    }

    selectEstate(shapeSn, callback) {
        if (shapeSn in this._selectedEstate) {
            return;
        }

        if (!shapeSn) {
            console.warn("invalid estate_sn: ", shapeSn);
            return;
        }

        fetchGeoJsonFeatures(`/api/map/shape_${shapeSn}`).then((features) => {
            if (!features) return;

            for (let estateSn in this._selectedEstate) {
                // 取消其它已选中的图形
                this.unselectEstate(estateSn);
            }

            this.getSource().addFeatures(features);
            this._selectedEstate[shapeSn] = features;
            if (callback) {
                callback({ estateSn: shapeSn, selected: true });
            }
        });
    }

    unselectEstate(shapeSn, callback) {
        if (!(shapeSn in this._selectedEstate)) {
            return;
        }

        // console.log("unselect estateSn: ", shapeSn);

        const source = this.getSource();
        for (let feature of this._selectedEstate[shapeSn]) {
            // console.log("unselect: ", feature);
            source.removeFeature(feature);
        }

        delete this._selectedEstate[shapeSn];

        if (callback) {
            callback({ estateSn: shapeSn, selected: false });
        }
    }

    clearSelected() {
        this.getSource().clear();
    }
}

const featureSelectedStyle = new Style({
    stroke: new Stroke({
        color: "hsla(28, 100%, 53%, 1)",
        width: 1,
    }),
    fill: new Fill({
        color: "hsla(28, 100%, 53%, 0.2)",
    }),
});
