import React from "react";

import { Route, Redirect, Switch } from "react-router-dom";
import styled from "styled-components";

import FirmListPage from "./firms/FirmListPage";
import FirmSummaryPage from "./firms/FirmSummaryPage";

export default function DataManagement(props) {
    return (
        <StyledDiv className="datamngt">
            <Switch>
                <Route
                    path={"/datamngt/firm/:firmSn(\\d+)/:tabId"}
                    component={FirmSummaryPage}
                />
                <Route
                    exact
                    path={"/datamngt/firm/list"}
                    component={FirmListPage}
                />
                <Route>
                    <Redirect to={"/datamngt/firm/list?page=0"} />
                </Route>
            </Switch>
        </StyledDiv>
    );
}

const StyledDiv = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
`;
