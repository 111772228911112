import React from "react";
import styled from "styled-components";
import { useGetJson } from "../../util/async";
import ActionCurtain from "../../util/ActionCurtain";

import FirmProfile from "./FirmProfile";
import AnimatedPane from "../../map/AnimatedPane";

export default function FeaturePane({ estate, dispatch }) {
    console.log("estate: ", estate);

    const { result: profile, ...loader } = useGetJson(() => {
        if (estate === null || estate.estateSn === null) return null;

        return `/api/lot/${estate.estateSn}/profile`;
    }, [estate]);


    if (loader.error) {
        return (
            <StyledPanel>
                <ActionCurtain action={loader} />
            </StyledPanel>
        );
    }

    console.log(1111, profile)

    const paneKey =
        profile && profile.firms.length > 0 ? profile.shape_sn : null;

    return (
        <StyledPanel>
            <AnimatedPane id={paneKey} timeout={350} classNames="display">
                {profile &&
                    profile.firms.map((firm) => (
                        <FirmProfile
                            key={firm.firm_sn}
                            profile={firm}
                            dispatch={dispatch}
                        />
                    ))}
            </AnimatedPane>
        </StyledPanel>
    );
}

const StyledPanel = styled.div`
    position: absolute;
    left: 0;
    width: 25rem;

    .action-fence {
        background-color: #fff;
    }
`;
