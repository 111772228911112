import React from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";

import { useGetJson } from "../../util/json";
import { timeago } from "../../util/datetime";

export default ({ firmSn }) => {
    const { data: revisions } = useGetJson(
        `/api/firm/${firmSn}/profile_history`
    );

    if (!revisions) {
        return null;
    }

    return (
        <Timeline align="left" className="revisions">
            {revisions.map((item) => (
                <TimelineItem key={item.rev_sn}>
                    <TimelineOppositeContent>
                        {timeago(new Date(item.rev_time))}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                        <div className="item">
                            <div className="notes">{item.rev_notes}</div>
                        </div>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};

