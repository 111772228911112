// import LayerGroup from "ol/layer/Group";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
// import GeoJSON from "ol/format/GeoJSON";
import { Style } from "ol/style";
import { Fill, Stroke } from "ol/style";
// import Point from "ol/geom/Point";
// import { Icon } from "ol/style";
// import Feature from "ol/Feature";
// import { getCenter } from "ol/extent";
// import Pbf from "pbf";
// import geobuf from "geobuf";
// import firmIcon from "./icons/firm2_64.png";
// import smokestackIcon from "./icons/smokestack2.png";
// import outfallIcon from "./icons/outfall2.png";
// import { fetchFeatures } from "./GeopointFetcher";

// const geojsonFormat = new GeoJSON();
const highlightStyle = new Style({
    stroke: new Stroke({
        color: "#f00",
        width: 3,
    }),
    fill: new Fill({
        color: "rgba(255,0,0,0.3)",
    }),
});

export default class LotShapeLayer extends VectorLayer {
    constructor() {
        super({
            source: new VectorSource(),
            style: highlightStyle,
        });
    }

    setMap(map) {
        this._map = map;
    }

    showFeatures(features) {
        if (!features || features.length === 0) {
            return;
        }

        const source = this.getSource();
        this._selectedEstate = {};

        source.clear();
        source.addFeatures(features);

        let [width, height] = this._map.getSize();
        width = Math.trunc(width / 5);
        height = Math.trunc(height / 5);

        this._map.getView().fit(source.getExtent(), {
            nearest: true,
            padding: [height, width, height, width],
        });
    }

    // selectFirm(firmSn, callback) {
    //     const source = this.getSource();
    //     this._selectedEstate = {};

    //     fetchFirmEstateFeature(firmSn).then((features) => {
    //         let estateFirmSn = null;
    //         source.clear();
    //         source.addFeatures(features);

    //         let [width, height] = this._map.getSize();
    //         width = Math.trunc(width / 5);
    //         height = Math.trunc(height / 5);

    //         this._map.getView().fit(source.getExtent(), {
    //             nearest: true,
    //             padding: [height, width, height, width],
    //         });

    //         callback && callback({ firmSn: estateFirmSn });
    //     });
    // }
}

// function fetchFirmEstateFeature(firmSn) {
//     return fetch(`/api/map/firm/${firmSn}/lot_shape`)
//         .then((response) => {
//             if (response.ok) return response.arrayBuffer();
//             console.error(`fetch ${response.status}: ${response.statusText}`);
//         })
//         .then((data) => {
//             const geojson = geobuf.decode(new Pbf(data));
//             if (!geojson || !geojson.features) {
//                 console.error("加载无效的GeoJSON");
//                 return [];
//             }
//             // console.log(11111, geojson);
//             return geojsonFormat.readFeatures(geojson);
//         });
// }
