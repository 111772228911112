import {useMemo} from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function useReturnPath(defaultReturnPath) {
    const location = useLocation();
    const history = useHistory();
    return useMemo(() => {
        let returnPath = location.state?.referer;
        if (typeof returnPath === "undefined") {
            returnPath = defaultReturnPath;
        }

        if (!returnPath) {
            return () => {
                console.error("no return-path");
                history.goBack();
            };
            // return null;
        }

        return () => {
            history.push(returnPath);
        };
    }, [location, history, defaultReturnPath]);
}
