import { useEffect, useState, useRef, useMemo, useCallback } from "react";
// import useClickFeature from "../map/useFeatureClick";

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function useGetJson() {
    let url, options, dependencies;
    if (arguments.length === 2) {
        // (url, options)
        [url, dependencies] = arguments;
        if (!Array.isArray(dependencies)) {
            options = dependencies;
            dependencies = undefined;
        }
    } else if (arguments.length === 3) {
        // (url, options, dependencies)
        [url, options, dependencies] = arguments;
    }

    const getRequest = useCallback(
        () => {
            if (typeof url === "function") {
                let _url = url();

                let request;
                if (typeof _url === "string") {
                    request = { url: _url };
                } else if (typeof _url === "object" && _url !== null) {
                    request = _url;
                } else {
                    request = { url: null };
                }
                return request;
            }

            let _url = url;
            if (_url && options && options.searchParams) {
                const qs = new URLSearchParams();
                const params = options.searchParams;
                Object.keys(params)
                    .filter(
                        (k) => params[k] !== undefined && params[k] !== null
                    )
                    .forEach((k) =>
                        qs.append(k, encodeURIComponent(params[k]))
                    );
                _url += "?" + qs.toString();
            }
            return { url: _url, ...options };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        dependencies
    );

    // console.log("useGetJson: ", url, options, dependencies);

    return useAsyncFunction(async () => {
        const { url, ...settings } = getRequest();
        if (!url) {
            return null;
        }
        // console.log("request-url:", url);

        const response = await fetch(url, settings);
        // console.log("resp: ", response);
        const { status } = response;
        if (status >= 400) {
            if (status === 404) {
                throw new Error("资源不存在");
            }

            console.error("error: ", response);
            throw new Error(`发生错误${response.statusText}`);
        }
        // console.log("resp: ", response);
        return response.json();
    }, [getRequest]);
}

export function useAsyncFunction(asyncFn, dependencies) {
    const promiseRef = useRef(null);
    const [count, setCount] = useState(0);
    const [state, setState] = useState(() => {
        return {
            result: null,
            error: null,
            busy: true, // 初始设置为true，避免初始化开始认为是空数据
        };
    });

    useEffect(
        () => {
            const _promise = makePromiseCancelable(asyncFn());
            promiseRef.current = _promise;
            if (!state.processing) {
                setState({
                    result: state.result,
                    busy: true,
                });
            }
            _promise.promise
                .then((_result) => {
                    promiseRef.current = null;
                    setState({
                        result: _result,
                        error: null,
                        busy: false,
                    });
                })
                .catch((_error) => {
                    promiseRef.current = null;
                    if (_error.isCanceled) {
                        return;
                    }
                    setState({
                        result: null,
                        error: _error,
                        busy: false,
                    });
                });

            return () => {
                _promise.cancel();
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...dependencies, count]
    );

    const { result, error, busy } = state;
    return {
        result,
        error,
        busy,
        success: !busy && error === null,
        reload() {
            setCount(count + 1);
        },
    };
}

export function useAsyncAction() {
    const promiseRef = useRef(null);
    const [state, setState] = useState(() => {
        return {
            result: null,
            error: null,
            processing: false,
        };
    });

    useEffect(() => {
        return () => {
            if (promiseRef.current) {
                promiseRef.current.cancel();
            }
        };
    }, []);

    const actionWait = useCallback(
        (asyncFnPromise) => {
            if (promiseRef.current) {
                console.warn("be processing, cannot run the action");
                return;
            }

            promiseRef.current = makePromiseCancelable(asyncFnPromise);
            setState({
                result: null,
                error: null,
                processing: true,
            });

            promiseRef.current.promise
                .then((result) => {
                    promiseRef.current = null;
                    setState({
                        result: result,
                        error: null,
                        processing: false,
                    });
                })
                .catch((error) => {
                    promiseRef.current = null;
                    if (error.isCanceled) {
                        return;
                    }
                    console.error(error);
                    setState({
                        result: null,
                        error: error,
                        processing: false,
                        // count: state.count
                    });
                });
        },
        [setState]
    );

    const actionStatus = useMemo(() => {
        // console.debug("async loading: ", state);
        return {
            result: state.result,
            error: state.error,
            processing: state.processing,
        };
    }, [state]);

    return [actionWait, actionStatus];
}

/**
 * 使得Promise提前cancel。并不是真正的cancel正在执行的程序，而是告诉调用者程序已经中断。
 * @param {*} promise
 */
export function makePromiseCancelable(promise) {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (val) =>
                hasCanceled_
                    ? reject(new Error({ isCanceled: true }))
                    : resolve(val),
            (error) =>
                hasCanceled_
                    ? reject(new Error({ isCanceled: true }))
                    : reject(error)
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
}
