import React from "react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import LinearProgress from "@material-ui/core/LinearProgress";
import clsx from "clsx";

import { useAsyncAction } from "./async";

const AsyncFunction = Object.getPrototypeOf(async function() {}).constructor;

export default function ActionButton({
    className,
    onAction,
    children,
    ...props
}) {
    const [actionWait, actionStatus] = useAsyncAction();

    const elemRef = useRef(null);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (actionStatus.error) {
            setShowError(true);
        }
    }, [actionStatus]);

    // console.debug("action-button: ", showError, actionStatus);
    return (
        <StyleButton className={clsx("action-button", className)}>
            <Button
                {...props}
                ref={elemRef}
                onClick={event => {
                    if (!onAction) {
                        console.warn("onAction is required");
                        return;
                    }

                    if (onAction.constructor === AsyncFunction) {
                        actionWait(onAction(event));
                    } else if (onAction.constructor === Function) {
                        actionWait((async e => onAction(e))(event));
                    }
                }}
            >
                {children}
            </Button>
            {actionStatus.processing && <LinearProgress color="secondary" />}
            {showError && (
                <Popover
                    open={true}
                    anchorEl={elemRef.current}
                    onClose={() => setShowError(false)}
                    anchorOrigin={{
                        vertical: "center",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <StyledPopover onClick={() => setShowError(false)}>
                        <ErrorMessage error={actionStatus.error} />
                    </StyledPopover>
                </Popover>
            )}
        </StyleButton>
    );
}

function ErrorMessage({ error }) {
    if (!error) return <span>发生错误</span>;

    if (error.message) return <span>{error.message}</span>;

    return <span>{error.toString()}</span>;
}

const StyleButton = styled.div`
    width: fit-content;
    .MuiLinearProgress-root {
        height: 2px;
        margin: -2px 4px 0 4px;
    }
`;

const StyledPopover = styled.div`
    padding: 15px 10px;
    background-color: rgb(246, 213, 213);

    min-width: 6em;
    text-align: center;
`;
