import { Vector as VectorSource } from "ol/source";
import Pbf from "pbf";
import geobuf from "geobuf";
import GeoJSON from "ol/format/GeoJSON";
import Heatmap from "./Heatmap";

const geojsonFormat = new GeoJSON();

export default class HeatmapLayer extends Heatmap {
    constructor() {
        super({
            source: new VectorSource({
                format: new GeoJSON(),
            }),
            blur: 90,
            radius: 50,
        });
    }

    loadMeasurement(indicator) {
        if (!indicator) {
            return;
        }

        loadFeatures(indicator).then((features) => {
            const source = this.getSource();
            source.clear();
            source.addFeatures(features);
        });
    }
}

async function loadFeatures(indicator) {
    const resp = await fetch(`/api/heatpoints/yr/${indicator}`);
    const geojson = geobuf.decode(new Pbf(await resp.arrayBuffer()));

    if (!geojson || !geojson.features) {
        console.error("加载无效的GeoJSON");
        return [];
    }

    return geojsonFormat.readFeatures(geojson);
}
