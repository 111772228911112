// import TileGrid from "ol/tilegrid/TileGrid";

import MVT from "ol/format/MVT";
import { VectorTile as VectorTileSource } from "ol/source";
import { VectorTile as VectorTileLayer } from "ol/layer";
import { Style } from "ol/style";
// import { Icon } from "ol/style";
import { Fill, Stroke } from "ol/style";

import { getProjection } from "../projection";

const TILE_EXTENT = [39000000, 3950000, 39800000, 4750000];
const projection = getProjection("EPSG:4527");

projection.setExtent(TILE_EXTENT);

const featureStyle = new Style({
    stroke: new Stroke({
        color: "hsl(200, 76%, 38%)",
        width: 1,
    }),
    fill: new Fill({
        color: "rgba(0, 0, 0, 0.01)",
    }),
});

export default class ShapeTileLayer extends VectorTileLayer {
    constructor() {
        super({
            source: new VectorTileSource({
                format: new MVT(),
                projection: projection,
                maxZoom: 18,
                url: "/api/map/mvt/{z}_{x}_{y}.pbf",
            }),
            style: (feature) => {
                return featureStyle;
            },
        });
    }
}
