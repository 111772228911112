import React from "react";
import styled from "styled-components";

export default React.forwardRef(({ className, children }, ref) => {
    return (
        <StyledDiv ref={ref} className={`page-body ${className || ""}`}>
            {children}
        </StyledDiv>
    );
});

const StyledDiv = styled.div`
    /* flex: 1 1; */
    padding: 1em 2em;
    /* height: calc(100% - 6rem); */

    justify-content: center;


    display: flex;
    flex-direction: column;
`;
