import React from "react";
import styled from "styled-components";

import clsx from "clsx";

export default function FirmProfile(props) {
    const { profile } = props;

    console.log("firm-profile: ", profile.firm_sn);

    return (
        <Styled className="firm-profile">
            <h3 className="title">
                <div className="firm-name">{profile.firm_name}</div>
                {profile.firm_size && (
                    <div className="firm-size">{profile.firm_size}</div>
                )}
                {profile.firm_state && (
                    <div
                        className={clsx(
                            "firm-state",
                            profile.firm_state === "运行" && "open"
                        )}
                    >
                        {profile.firm_state}
                    </div>
                )}
            </h3>

            <div className="usci-row">
                <label>统一信用代码：</label>
                <div className="usci">{profile.usci}</div>
                <div className="usci-seqno">
                    {profile.usci_seqno && (
                        <>
                            (<span>{profile.usci_seqno}</span>)
                        </>
                    )}
                </div>
            </div>

            <div className="address">
                <div className="division-code">{profile.division_code}</div>
                <div className="street">{profile.street}</div>
            </div>
        </Styled>
    );
}

const Styled = styled.div`
    margin: 10px 0;
    padding: 15px 10px;
    background-color: #fff;
    box-shadow: 2px 2px #ccc;

    .title {
        display: flex;

        .firm-name {
            flex: 1 1;
            font-weight: bold;
        }

        .firm-size {
            border-style: solid;
            border-width: 1px;
            border-color: #999;

            padding: 2px;
            font-size: 0.8em;
            margin-right: 2px;

            height: 2em;
        }

        .firm-state {
            flex: 0 0 auto;
            color: #fff;
            padding: 2px 4px;
            background-color: hsl(0, 84%, 39%);
            font-size: 0.8em;

            height: 2em;

            &.open {
                background-color: hsl(120, 68%, 24%);
            }
        }
    }

    .usci-row {
        display: flex;
        align-items: baseline;

        label {
            font-size: 0.8em;
            /* margin-right: 0.5em; */
        }

        .usci {
            font-weight: bold;
        }

        .usci-seqno {
            color: #bbb;
            margin-left: 0.5em;
            > span {
                color: #666;
                font-weight: bold;
            }
        }
    }

    .address {
        display: flex;
    }
`;
