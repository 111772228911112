import React from "react";
import {
    useState,
    useEffect,
    useCallback,
    useImperativeHandle,
    useRef,
} from "react";
import styled from "styled-components";

import OLMap from "ol/Map";
import OLMapView from "ol/View";

import { defaultSRID, fromLonLat } from "../../map/projection";
import ZoomButton from "../../map/control/ZoomButton";
import ShapeHighlightLayer from "./ShapeHighlightLayer";
import TiandituLayer from "../../map/layer/TiandituLayer";
import ShapeTileLayer from "../../map/layer/ShapeTileLayer";
import LotShapeLayer from "../../map/layer/LotShapeLayer";
import { handleMapClick } from "../../map/util";
import { fetchGeoJsonFeatures } from "../../map/geojson";

function FirmMapView({ firmSn, onLotShapeSelect }, ref) {
    const mapDivRef = useRef(null);
    const lotShapeLayerRef = useRef(null);
    const mapRef = useRef(null);
    const [lotShapes, setLotShapes] = useState(null);

    const fetchLotShapes = useCallback(() => {
        if (firmSn) {
            fetchGeoJsonFeatures(`/api/map/firm/${firmSn}/lot_shape`).then(
                (features) => {
                    setLotShapes(features);
                    lotShapeLayerRef.current.showFeatures(features);
                }
            );
        }
    }, [firmSn]);

    useImperativeHandle(
        ref,
        () => ({
            selectFirm: () => {
                console.debug("mapview, select-firm:", firmSn);
                fetchLotShapes();
            },
            selectLotShape: (shapeSn) => {
                console.debug("mapview, select-lot-shape: ", shapeSn);
                if (!lotShapes) return;
                for (let feature of lotShapes) {
                    if (feature.get("shape_sn") === shapeSn) {
                        lotShapeLayerRef.current.showFeatures([feature]);
                    }
                }
            },
        }),
        [firmSn, lotShapes, fetchLotShapes]
    );

    useEffect(() => {
        if (!mapDivRef) return;

        const tiandituLayer = new TiandituLayer();
        const shapeTileLayer = new ShapeTileLayer();
        // const firmOutlineLayer = new FirmOutlineTileLayer();
        const lotShapeLayer = new LotShapeLayer();
        const estateHighlightLayer = new ShapeHighlightLayer();

        const olmap = new OLMap({
            controls: [],
            layers: [
                tiandituLayer,
                shapeTileLayer,
                lotShapeLayer,
                // firmOutlineLayer,
                estateHighlightLayer,
            ],
            view: new OLMapView({
                projection: defaultSRID,
                center: fromLonLat([117.52, 39.09]),
                maxZoom: 13,
                minZoom: 2,
                zoom: 8,
            }),
        });

        mapRef.current = olmap;
        // shapeLayerRef.current = shapeTileLayer;
        lotShapeLayerRef.current = lotShapeLayer;

        estateHighlightLayer.setMap(olmap);
        lotShapeLayer.setMap(olmap);
        olmap.set("estateHighlightLayer", estateHighlightLayer);
        olmap.set("lotShapeLayer", lotShapeLayer);

        olmap.setTarget(mapDivRef.current);

        const clearMapClick = handleMapClick(olmap, (feature, layer) => {
            let handled;

            if (estateHighlightLayer) {
                handled = estateHighlightLayer.handleClickEvent(
                    feature,
                    layer,
                    (target) => {
                        const { estateSn, selected } = target;

                        if (onLotShapeSelect) {
                            onLotShapeSelect({ shapeSn: estateSn, selected });
                        }
                    }
                );
                if (handled) return true;
            }
        });

        return () => {
            clearMapClick && clearMapClick();
        };
    }, [onLotShapeSelect]);

    return (
        <Styled>
            <div className="map" ref={mapDivRef} />
            <ZoomButton mapObj={mapRef.current} />
        </Styled>
    );
}

export default React.forwardRef(FirmMapView);

const Styled = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    position: relative;
    box-shadow: 4px 4px 8px #aaa;

    background-color: #f2f2f2;

    .map-background {
        /* position: absolute; */
        background-repeat: no-repeat;
        background-size: contain;

        width: 100%;
        height: 70%;
        background-repeat: no-repeat;
        background-size: contain;
        bottom: -5%;
        left: -5%;
        filter: hue-rotate(300deg);
        opacity: 0.3;
    }

    .page-container {
        /* position: absolute; */
        width: 100%;
        height: 100%;
        pointer-events: none;

        .page {
            pointer-events: all;
        }
    }

    .map {
        width: 100%;
        height: 100%;
    }
`;
