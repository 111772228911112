import React from "react";

export default function ErrorIcon() {
    return (
        <svg
            height="100%"
            viewBox="0 0 60 60"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g id="Page-1" fill="none" fillRule="evenodd">
                <g id="030---Cloud-File-Error" fillRule="nonzero">
                    <path
                        id="Shape"
                        d="m13 37h-1c-6.07513225 0-11-4.9248678-11-11s4.92486775-11 11-11c.623565-.0030798 1.2461215.0504735 1.86.16 1.427149-3.0326141 4.4212431-5.02097 7.77-5.16 1.9678376-6.08875505 8.0738821-9.84005753 14.3946544-8.84348326s10.976386 6.4446301 10.9753456 12.84348326c.019924.3497165.019924.7002835 0 1.05.34 0 .7-.05 1-.05 6.0751322.0000001 10.9999998 4.9248678 10.9999998 11s-4.9248676 10.9999999-10.9999998 11z"
                        fill="#00bcd4"
                    />
                    <path
                        id="Shape"
                        d="m48 15c-.35 0-.71 0-1 .05.019924-.3497165.019924-.7002835 0-1.05 0-3.4478144-1.3696389-6.75441524-3.8076118-9.19238816-2.437973-2.43797292-5.7445738-3.80761184-9.1923882-3.80761184-.49788.00446883-.9952019.0345084-1.49.09 6.5501491.76600988 11.4889823 6.31521239 11.49 12.91.019924.3497165.019924.7002835 0 1.05.34 0 .7-.05 1-.05 5.6723409.0182317 10.4006953 4.3467764 10.9186843 9.9954462s-3.3442621 10.7649821-8.9186843 11.8145538v.19h1c6.0751322 0 11-4.9248678 11-11s-4.9248678-11-11-11z"
                        fill="#00838f"
                    />
                    <path
                        id="Shape"
                        d="m40.83 9.15c-.2923118.00001923-.5699973-.12786225-.76-.35-1.5177498-1.77444941-3.7349995-2.79723347-6.07-2.8-.5522847 0-1-.44771525-1-1s.4477153-1 1-1c2.9180174.00022682 5.6903707 1.27499794 7.59 3.49.1724034.201559.2576705.46335301.2370412.72778336-.0206293.26443034-.145465.50983336-.3470412.68221664-.179713.15852397-.4103774.24724105-.65.25z"
                        fill="#f5f5f5"
                    />
                    <path
                        id="Shape"
                        d="m46 31v22c0 3.3137085-2.6862915 6-6 6h-20c-3.3137085 0-6-2.6862915-6-6v-26c0-3.3137085 2.6862915-6 6-6h16v6c0 2.209139 1.790861 4 4 4z"
                        fill="#ffe0b2"
                    />
                    <path
                        id="Shape"
                        d="m37 31h3c-2.209139 0-4-1.790861-4-4v-6h-3v6c0 2.209139 1.790861 4 4 4z"
                        fill="#dfc49c"
                    />
                    <path
                        id="Shape"
                        d="m43 31v22c0 3.3137085-2.6862915 6-6 6h3c3.3137085 0 6-2.6862915 6-6v-22z"
                        fill="#dfc49c"
                    />
                    <path
                        id="Shape"
                        d="m46 31h-6c-2.209139 0-4-1.790861-4-4v-6z"
                        fill="#ffe0b2"
                    />
                    <path
                        id="Shape"
                        d="m32 31c0 1.1-2 12-2 12s-2-10.9-2-12c0-1.1045695.8954305-2 2-2s2 .8954305 2 2z"
                        fill="#9d27b0"
                    />
                    <circle id="Oval" cx="30" cy="49" fill="#9d27b0" r="2" />
                    <g fill="#000">
                        <path
                            id="Shape"
                            d="m48 14c-.0137806-6.75848529-4.8538456-12.54228148-11.5040745-13.74717535s-13.2118426 2.51313692-15.5959255 8.83717535c-3.1853678.34613608-6.0079851 2.2080208-7.58 5-.4379481-.0548953-.8786538-.0849434-1.32-.09-6.627417 0-12 5.372583-12 12s5.372583 12 12 12h1v15c0 3.8659932 3.1340068 7 7 7h20c3.8659932 0 7-3.1340068 7-7v-15h1c6.627417 0 12-5.372583 12-12s-5.372583-12-12-12zm-3 39c0 2.7614237-2.2385763 5-5 5h-20c-2.7614237 0-5-2.2385763-5-5v-26c0-2.7614237 2.2385763-5 5-5h15v5c0 2.7614237 2.2385763 5 5 5h5zm-8-29.59 6.59 6.59h-3.59c-1.6568542 0-3-1.3431458-3-3zm11 12.59h-1v-5c.0015368-.265803-.1028135-.5212812-.29-.71l-10-10c-.1887188-.1871865-.444197-.2915368-.71-.29h-16c-3.8659932 0-7 3.1340068-7 7v9h-1c-5.5228475 0-10-4.4771525-10-10s4.4771525-10 10-10c1.9730774-.0063323 3.9027673.5788444 5.54 1.68.3001031.2000687.6841452.2248368 1.0074614.0649742.3233161-.1598625.5367869-.4800687.56-.84.023213-.3599312-.1473583-.7049055-.4474614-.9049742-1.0022971-.6732402-2.1016771-1.1892069-3.26-1.53 1.4344209-2.0914501 3.7755523-3.3788869 6.31-3.47h.22c1.7580666-.0361103 3.475905.528272 4.87 1.6.4418278.3313708 1.0686291.2418278 1.4-.2.3313708-.4418278.2418278-1.0686291-.2-1.4-1.4368206-1.08209346-3.1410594-1.75272698-4.93-1.94 2.3315558-5.14486251 7.9354541-7.96718928 13.457282-6.77757612s9.466469 6.06906094 9.472718 11.71757612v.17c-1.6662638.2699658-3.2552963.893985-4.66 1.83-.4583963.3092795-.5792795.9316037-.27 1.39s.9316037.5792795 1.39.27c1.6394914-1.0940872 3.5690082-1.6722457 5.54-1.66 5.5228475 0 10 4.4771525 10 10s-4.4771525 10-10 10z"
                        />
                        <path
                            id="Shape"
                            d="m30 28c-1.6568542 0-3 1.3431458-3 3 0 1.14 1.68 10.35 2 12.18 0 .5522847.4477153 1 1 1s1-.4477153 1-1c.34-1.83 2-11 2-12.18 0-1.6568542-1.3431458-3-3-3zm0 9.33c-.57-3.23-1-5.89-1-6.33 0-.5522847.4477153-1 1-1s1 .4477153 1 1c0 .44-.43 3.1-1 6.33z"
                        />
                        <path
                            id="Shape"
                            d="m30 46c-1.6568542 0-3 1.3431458-3 3s1.3431458 3 3 3 3-1.3431458 3-3-1.3431458-3-3-3zm0 4c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1 1 .4477153 1 1-.4477153 1-1 1z"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
}
