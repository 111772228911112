import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Style } from "ol/style";
// import { Fill, Stroke } from "ol/style";
import Point from "ol/geom/Point";
import { Icon } from "ol/style";
import Feature from "ol/Feature";
import { getCenter } from "ol/extent";

import LayerGroup from "ol/layer/Group";
import firmIcon from "./icons/firm2_64.png";
import smokestackIcon from "./icons/smokestack2.png";
import outfallIcon from "./icons/outfall2.png";

import { fetchFeatures } from "./GeopointFetcher";

export default class GeopointLayer extends LayerGroup {
    constructor() {
        const firmGeopointLayer = new VectorLayer({
            source: new VectorSource(),
            style: firmStyleFunc,
        });

        const outfallGeopointLayer = new VectorLayer({
            source: new VectorSource(),
            style: outfallStyleFunc,
        });

        const smokestackGeopintLayer = new VectorLayer({
            source: new VectorSource(),
            style: smokestackStyleFunc,
        });

        super({
            layers: [
                firmGeopointLayer,
                outfallGeopointLayer,
                smokestackGeopintLayer,
            ],
            minZoom: 10,
        });

        this.selected = [];

        fetchFeatures(`/api/geobuf/mark/firm`).then((features) => {
            // console.log("aaa", features);
            firmGeopointLayer.getSource().addFeatures(features);
        });

        fetchFeatures(`/api/geobuf/mark/outfall`).then((features) => {
            outfallGeopointLayer.getSource().addFeatures(features);
        });

        fetchFeatures(`/api/geobuf/mark/smokestack`).then((features) => {
            smokestackGeopintLayer.getSource().addFeatures(features);
        });
    }

    setMap(map) {
        this._map = map;
    }

    handleClickEvent(feature, layer, callback) {
        if (feature.getGeometry().constructor !== Point) return false;

        if (!feature) return false;

        if (!this.isSelected(feature)) {
            this.select(feature, callback);
        } else {
            this.unselect(feature, callback);
        }
        return true;
    }

    isSelected(feature) {
        if (!feature || feature.constructor !== Feature) return;

        return feature.get("selected") === true;
    }

    select(feature, callback) {
        const target = {
            firmSn: feature.get("firm_sn"),
            indicatorNo: feature.get("indicator_no"),
            targetNo: feature.get("target_no"),
            selected: true,
        };

        for (let f of this.selected) {
            if (f === feature) continue;
            f.set("selected", false);
        }
        feature.set("selected", true);
        this.selected = [feature];

        if (callback) callback(target);
    }

    unselect(feature, callback) {
        const target = {
            firmSn: feature.get("firm_sn"),
            indicatorNo: feature.get("indicator_no"),
            targetNo: feature.get("target_no"),
            selected: false,
        };

        for (let f of this.selected) {
            f.set("selected", false);
        }
        this.selected = [];

        if (callback) callback(target);
    }

    selectFirm(firmSn, callback) {
        const layers = this.getLayers().getArray();
        const firmGeopointLayer = layers[0];

        let feature;
        for (let f of firmGeopointLayer.getSource().getFeatures()) {
            if (f.get("firm_sn") === firmSn) {
                feature = f;
                break;
            }
        }

        if (!feature) {
            console.debug("no found firm geopoint: ", firmSn);
            // TODO 查询节点，居中默认到指点
            return;
        }

        const target = {
            firmSn: feature.get("firm_sn"),
            indicatorNo: feature.get("indicator_no"),
            targetNo: feature.get("target_no"),
        };

        if (this._map.getView().getZoom() <= this.getMinZoom()) {
            this._map.getView().setZoom(this.getMinZoom() + 1);
        }

        // console.log("_map zoom", this._map.getView().getZoom())

        const center = getCenter(feature.getGeometry().getExtent());
        this._map && this._map.getView().setCenter(center);

        this.select(feature, callback);

        if (callback) callback(target);
    }
}

function firmStyleFunc(feature) {
    // const firmSn = feature.get("firm_sn");
    let imgColor;
    if (feature.get("selected")) {
        imgColor = "hsla(53, 88%, 48%, 0.89)";
    }

    const firmMarker = new Style({
        image: new Icon({
            opacity: 1,
            scale: 0.7,
            color: imgColor,
            src: firmIcon,
            imgSize: [64, 64],
        }),
    });

    return firmMarker;
}

function outfallStyleFunc(feature) {
    return outfallMarker;
}

function smokestackStyleFunc(feature) {
    return smokestackMarker;
}

const smokestackMarker = new Style({
    image: new Icon({
        opacity: 1,
        scale: 1,
        src: smokestackIcon,
        imgSize: [32, 32],
    }),
});

const outfallMarker = new Style({
    image: new Icon({
        opacity: 1,
        scale: 1,
        src: outfallIcon,
        imgSize: [32, 32],
    }),
});
